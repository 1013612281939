import React from 'react';
import Activity from '../backend/Activity';
import ActivityView from './ActivityView';
import {
    Input,
    InputGroup,
    Button
  } from "reactstrap";
import { globalMainPage } from './MainPage';
  

/**
 * A search box, to be placed in the site topbar.
 * 
 * Props:
 */
class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.searchTimer = null;
    this.state = {
      searchText: "",
      resultState: "none",  // "none", "found", "notfound"
      results: [],
    }
  }

  scheduleSearch = () => {
    if (this.searchTimer)
      clearTimeout(this.searchTimer);

    this.searchTimer = setTimeout(() => { 
      this.search();
    }, 500);
  }

  handleOnChange = (event) => {
    this.setState({ searchText: event.target.value });
    if (event.target.value === '')
      this.setState({ resultState: "none" });

    if (this.state.searchText.length >= 3)
      this.scheduleSearch();
  }

  handleReset = () => {
    this.setState({ searchText: "", resultState: "none" });
  }

  search = async () => {
    console.log("Searching for: " + this.state.searchText);
    this.setState({ resultState: "loading" });
    let activities = await Activity.search(this.state.searchText);
    this.setState({ 
      results: activities, 
      resultState: (activities.length > 0) ? "found" : "notfound" 
    });
  }

  handleClickResult = (event, activity) => {
    event.preventDefault(); 
    event.stopPropagation();
    globalMainPage.goToActivity(activity);
    this.setState({ resultState: "none" });
  }

  render = () => {
    return (
      <div className="search-box">
        <InputGroup size="sm">
          <Input 
            type="text"
            bsSize="sm" 
            value={this.state.searchText}
            aria-label="Search" 
            placeholder="Search..." 
            onChange={this.handleOnChange}
            onFocus={this.handleOnFocus}
            // onBlur={this.handleReset}
          />
          <Button className="nofocus" onClick={this.handleReset}>
            <i className="bi bi-x"></i>
          </Button>
        </InputGroup>
        <ul className={`dropdown-menu search-results ${this.state.resultState !== 'none' ? 'show' : '' }`} style={{width: '100%', top: '40px'}}>
          {((this.state.resultState === 'loading') && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          )) || ((this.state.resultState === 'notfound') && (
            <>
              <p>Nothing found</p>
            </>
          )) || ((this.state.resultState === 'found') && (
            <>
            {this.state.results.map(activity => 
            <li key={activity.uuid}>
              <ActivityView 
                activity={activity} 
                showBreadcrumbs={true}
                hideSubactivities={true} 
                hideJournal={true} 
                onClick={(event) => this.handleClickResult(event, activity)}
              />
            </li>
            )}
            </>
          ))}
        </ul>
      </div>
    )
  }
}

export default SearchBox;

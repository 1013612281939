import PmApi from '../backend/PmApi';
import ObjLocalStorage from '../backend/ObjLocalStorage';
import BackendObject from '../backend/BackendObject';
import ActivityLabel from '../backend/ActivityLabel';
import PmUser from '../backend/PmUser';
import { isEqual, cloneDeep } from 'lodash';


export default class Activity extends BackendObject {
  // static STATE_NEW = 1;
  // static STATE_PLANNED = 2
  static STATE_TODO = 7
  static STATE_ONGOING = 3
  static STATE_ONHOLD = 4
  static STATE_COMPLETED = 5
  static STATE_CANCELLED = 6
  static STATES = {
    // [Activity.STATE_NEW]: {
    //   'name': 'New',
    //   'icon': <i className="bi bi-dash"></i>,
    // },
    // [Activity.STATE_PLANNED]: {
    //   'name': 'Planned',
    //   'icon': "⚬",
    // },
    [Activity.STATE_TODO]: {
      'name': 'To-do',
      'icon': "⚬",
    },
    [Activity.STATE_ONGOING]: {
      'name': 'Ongoing',
      'icon': <i className="bi bi-play"></i>,
    },
    [Activity.STATE_ONHOLD]: {
      'name': 'On hold',
      'icon': <i className="bi bi-pause"></i>,
    },
    [Activity.STATE_COMPLETED]: {
      'name': 'Completed',
      'icon': <i className="bi bi-check"></i>,
    },
    [Activity.STATE_CANCELLED]: {
      'name': 'Cancelled',
      'icon': <i className="bi bi-x"></i>,
    },
  }

  // static TYPE_DEFAULT = 1;
  // static TYPE_INFO = 2
  static TYPE_CHECKBOX = 3
  static TYPE_PROJECT = 4
  static TYPE_FOLDER = 5
  static TYPE_WORKSPACE = 6
  static TYPE_MEETING = 7
  static TYPES = {
    [Activity.TYPE_CHECKBOX]: {
      'name': 'Checkbox',
      'icon': <i className="bi bi-check2-square"></i>,
    },
    // [Activity.TYPE_DEFAULT]: {
    //   'name': 'Activity',
    //   'icon': <i className="bi bi-circle"></i>,
    // },
    // [Activity.TYPE_INFO]: {
    //   'name': '',
    //   'icon': <i className="bi bi-info"></i>,
    // },
    [Activity.TYPE_FOLDER]: {
      'name': 'Folder',
      'icon': <i className="bi bi-folder2-open"></i>,
    },
    [Activity.TYPE_PROJECT]: {
      'name': 'Project',
      'icon': <i className="bi bi-card-checklist"></i>,
    },
    [Activity.TYPE_WORKSPACE]: {
      'name': 'Workspace',
      'icon': <i className="bi bi-columns"></i>,
    },
    [Activity.TYPE_MEETING]: {
      'name': 'Meeting/Call',
      'icon': <i className="bi bi-people-fill"></i>,
    },
  }

  static TYPES_STATES = {
    [Activity.TYPE_CHECKBOX]: {
      [Activity.STATE_NEW]:       <i className="bi bi-dash-square"></i>,
      [Activity.STATE_PLANNED]:   <i className="bi bi-square"></i>,
      [Activity.STATE_TODO]:      <i className="bi bi-square"></i>,
      [Activity.STATE_ONGOING]:   <i className="bi bi-caret-right-square"></i>,
      [Activity.STATE_ONHOLD]:    <i className="bi bi-p-square"></i>,
      [Activity.STATE_COMPLETED]: <i className="bi bi-check-square"></i>,
      [Activity.STATE_CANCELLED]: <i className="bi bi-x-square"></i>,
    },
    // [Activity.TYPE_DEFAULT]: {
    //   [Activity.STATE_NEW]:       <i className="bi bi-dash-circle"></i>,
    //   [Activity.STATE_PLANNED]:   <i className="bi bi-circle"></i>,
    //   [Activity.STATE_ONGOING]:   <i className="bi bi-play-circle-fill"></i>,
    //   [Activity.STATE_ONHOLD]:    <i className="bi bi-pause-circle-fill"></i>,
    //   [Activity.STATE_COMPLETED]: <i className="bi bi-check-circle-fill"></i>,
    //   [Activity.STATE_CANCELLED]: <i className="bi bi-x-circle-fill"></i>,
    // },
  }

  static UNIT_MINUTES = 1;
  static UNIT_HOURS = 2
  static UNIT_DAYS = 3

  static PRIORITY_HIGH = 1;
  static PRIORITY_NORMAL = 0;
  static PRIORITY_LOW = -1;
  static PRIORITIES = {
    [Activity.PRIORITY_HIGH]: {
      'name': 'High priority',
      'icon': "🠱",
    },
    [Activity.PRIORITY_NORMAL]: {
      'name': 'Normal priority',
      'icon': "–",
    },
    [Activity.PRIORITY_LOW]: {
      'name': 'Low priority',
      'icon': "🠳",
    },
  }

  // Background color types
  static BG_TYPE_NONE = 0;
  static BG_TYPE_SOLID = 1;
  static BG_TYPE_GRADIENT = 2;
  static BG_TYPE_UNSPLASH = 3;

  static DEFAULT_VIEW_MODE = 'list';

  // Maps uuid to Activity object
  static uuidToActivity = new Map();

  // Get an Activity by its uuid
  static getByUuid(uuid) {
    if (Activity.uuidToActivity.has(uuid))
      return Activity.uuidToActivity.get(uuid);
    else 
      return null;
  }

  // Save an Activity by its uuid
  static async setByUuid(uuid, activity) {
    Activity.uuidToActivity.set(uuid, activity);
  }

  // Create a new local copy of Activity or update the existing local copy
  static updateLocal = (uuid, data, skipIfNotLoaded) => {
    if (Activity.uuidToActivity.has(uuid)) {
      let activity = Activity.uuidToActivity.get(uuid);
      activity.uuid = uuid;
      activity.setData(data);
      return activity;
    }
    else if (!skipIfNotLoaded) {
      let activity = new Activity(uuid, data);
      Activity.setByUuid(uuid, activity);
      return activity;
    }
  }

  // Create or update local copies of Activity. Return a list of activities
  static updateLocalMany = (data, skipIfNotLoaded) => {
    let activities = [];
    for (let i = 0; i < data.length; i++)
      activities.push(Activity.updateLocal(data[i].uuid, data[i], skipIfNotLoaded));

    return activities;
  }

  // Create a new activity, standalone or child of another activity
  static create = async (data, parent, after) => {
    const params = {}
    if (parent)
      params.parent = parent.uuid;
    if (after)
      params.after = after.uuid;

    const paramsStr = new URLSearchParams(params).toString();

    let url = `activities?${paramsStr}`;
    const api = new PmApi();
    let res = await api.post(url, data);
    const activity = Activity.updateLocal(res.data.uuid, res.data);
    activity.justCreated = true;
    return activity;
  }

  // Load a single activity; use the cached copy if available.
  static load = async (uuid) => {

    let activity = Activity.getByUuid(uuid);
    if (activity)
      return activity;

    const api = new PmApi();
    let res = await api.get(`activities/${uuid}`);
    // TODO: gestire i 404!
    return Activity.updateLocal(res.data.uuid, res.data);
  };

  // Search for text and return the list of best matching activities.
  static search = async (searchText) => {
    const api = new PmApi();
    let res = await api.get(`activities/search`, { search_text: searchText });
    return Activity.updateLocalMany(res.data);
  };

  constructor(uuid, data) {
    super();
    this.uuid = uuid;
    this.data = cloneDeep(data) || {};
    this.subactivities = null;
    this.deleted = false;
    this.justCreated = false;
  }

  // Update the data fields
  setData = (data) => {
    if (isEqual(data, this.data)) // lodash
        // No change
        return;

    this.data = cloneDeep(data);
    if (this.data.is_open) {
      this._setIsOpen(true);      
    }

    this.data.is_open = undefined;
    this.data.subactivities = undefined;
    Activity.updateLocalMany(data.subactivities);

    this.notifyRegisteredListeners();
  }

  // Refresh contents
  refresh = async () => {
    console.debug("Activity.refresh()");

    this.data = {};
    this.subactivities = null;

    const api = new PmApi();
    try {
      const res = await api.get(`activities/${this.uuid}`);
      this.setData(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  // Returns the "just created" flag
  // Used to auto-focus an activity when it is created.
  isJustCreated = () => {
    return this.justCreated;
  }

  // Clears the "just created" flag
  clearJustCreated = () => {
    this.justCreated = false;
  }

  // Change the activity state
  setState = async (state) => {
    const api = new PmApi();
    let res = await api.patch(`activities/${this.uuid}`, { state: state });
//    this.setData(res.data);
    Activity.updateLocalMany(res.data)
    return res;
  }

  // Change the activity due date
  setDueDate = async (date) => {
    const api = new PmApi();
    let res = await api.patch(`activities/${this.uuid}`, { due_date: date });
//    this.setData(res.data);
    Activity.updateLocalMany(res.data)
    return res;
  }

  // Change the activity estimated duration
  setEstimatedDuration = async (estimated_duration, unit) => {
    const api = new PmApi();
    let res = await api.patch(`activities/${this.uuid}`, { 
      estimated_duration: estimated_duration,
      estimated_duration_unit: unit,
    });
//    this.setData(res.data);
    Activity.updateLocalMany(res.data)
    return res;
  }
  
  // Change the activity type
  setType = async (type) => {
    const api = new PmApi();
    let res = await api.patch(`activities/${this.uuid}`, { type: type });
//    this.setData(res.data);
    Activity.updateLocalMany(res.data)
    return res;
  }

  // Change the activity priority
  setPriority = async (priority) => {
    const api = new PmApi();
    let res = await api.patch(`activities/${this.uuid}`, { priority_local: priority });
//    this.setData(res.data);
    Activity.updateLocalMany(res.data)
    return res;
  }

  // Change the activity title
  setTitle = async (title) => {
    const api = new PmApi();
    let res = await api.patch(`activities/${this.uuid}`, { title: title });
//    this.setData(res.data);
    Activity.updateLocalMany(res.data)
    return res;
  }

  // Change the activity background
  setBg = async (bgType, bgValue) => {
    const api = new PmApi();
    const data = { bg_type: bgType, bg_value: bgValue };
    let res = await api.patch(`activities/${this.uuid}`, data);
//    this.setData(res.data);
    Activity.updateLocalMany(res.data)
    return res;
  }

  // Change the activity open or closed
  // Return true if changed, false otherwise
  // Don't notify listeners
  _setIsOpen = (isOpen) => {
    const key = "activity-" + this.uuid;

    let obj = ObjLocalStorage.get(key);
    if (Boolean(obj.isOpen) === isOpen)
      return false;

    obj.isOpen = isOpen;
    ObjLocalStorage.set(key, obj);
    return true;

//     const api = new PmApi();
//     let res = await api.patch(`activities/${this.uuid}`, { is_open: open });
//     Activity.updateLocalMany(res.data)
//     return res;
  }
  
  // Change the activity open or closed & notify listeners in case of change
  setIsOpen = (isOpen) => {
    if (this._setIsOpen(isOpen))
      this.notifyRegisteredListeners();
  }

  // Return whether the activity is open or not
  getIsOpen = () => {
    const key = "activity-" + this.uuid;
    let obj = ObjLocalStorage.get(key);
    return Boolean(obj.isOpen);
  }

  // Change the activity open or closed
  // Return true if changed, false otherwise
  // Don't notify listeners
  setViewMode = (m) => {
    const key = "activity-" + this.uuid;

    let obj = ObjLocalStorage.get(key);
    obj.viewMode = m;
    ObjLocalStorage.set(key, obj);
    return true;
  }

  // Return whether the activity is open or not
  getViewMode = () => {
    const key = "activity-" + this.uuid;
    let obj = ObjLocalStorage.get(key);
    return obj.viewMode ? obj.viewMode : Activity.DEFAULT_VIEW_MODE;
  }

  // Change the activity journal open or closed
  setIsJournalOpen = (is_journal_open) => {
    if (is_journal_open === this.data.is_journal_open)
      return;
    this.data.is_journal_open = is_journal_open;
    this.notifyRegisteredListeners();

    // const api = new PmApi();
    // let res = await api.patch(`activities/${this.uuid}`, { is_journal_open: open });
    // Activity.updateLocalMany(res.data)
    // return res;
  }

  // Return whether the activity journal is open or not
  getIsJournalOpen = () => {
    return this.data.is_journal_open;
  }
  
  // Change the "is_private" state
  setPrivate = async (priv) => {
    const api = new PmApi();
    let res = await api.patch(`activities/${this.uuid}`, { is_private_local: priv });
//    this.setData(res.data);
    Activity.updateLocalMany(res.data)
    return res;
  }

  // Return the parent activity
  parentActivity = async () => {
    const api = new PmApi();
    try {
      let res = await api.get(`activities/${this.uuid}/parent`);
      return Activity.updateLocal(res.data.uuid, res.data);
    }
    catch (error) {
      if (error.response.status === 404)
        return null;
      else
        throw error;
    }
  }

  // Return the workspace (root activity)
  getWorkspace = async () => {
    let parent = await this.parentActivity();
    if (parent === null)
      return this;
    else
      return await parent.getWorkspace();
  }

  // Return the chain of activities from workspace (root activity) to this activity
  getChain = async () => {
    let parent = await this.parentActivity();
    if (parent === null)
      return [this];
    else
      return [...(await parent.getChain()), this];
  }

  // Return the previous sibling activity
  previousActivity = async () => {
    const api = new PmApi();
    try {
      let res = await api.get(`activities/${this.uuid}/previous`);
      return Activity.updateLocal(res.data.uuid, res.data);
    }
    catch (error) {
      if (error.response.status === 404) {
        return null;
      }
      else
        throw error;
    }
  }

  // Return the next sibling activity
  nextActivity = async () => {
    const api = new PmApi();
    try {
      let res = await api.get(`activities/${this.uuid}/next`);
      return Activity.updateLocal(res.data.uuid, res.data);
    }
    catch (error) {
      if (error.response.status === 404) {
        return null;
      }
      else
        throw error;
    }
  }

  // Delete this activity
  delete = async () => {
    const api = new PmApi();
    await api.delete(`activities/${this.uuid}`);
    this.deleted = true;
    Activity.uuidToActivity.delete(this.uuid);
  };

  // Move this activity as a child of `parent` and after the `after` activity
  moveTo = async (parent, after) => {
    const api = new PmApi();
    await api.patch(`activities/${this.uuid}/move`, { parent: parent ? parent.uuid : null, after: after ? after.uuid : null });
  };

  // Return the members of this activity
  getMembers = () => {
    // console.log("Activity.getMembers()");
    // console.log(this);
    if (this.data.uuids_of_members === undefined)
      alert("Undefined!");
    const pmUsers = this.data.uuids_of_members.map(uuid => PmUser.getByUuid(uuid));
    return pmUsers;
  };

  // Return the "all" members of this activity
  getAllMembers = () => {
    if (this.data.uuids_of_all_members === undefined)
      alert("Undefined!");
    const pmUsers = this.data.uuids_of_all_members.map(uuid => PmUser.getByUuid(uuid));
    return pmUsers;
  };

  // Set the members of this activity
  setMembers = async (uuids_of_members) => {
    const api = new PmApi();
    let res = await api.put(`activities/${this.uuid}/members`, { uuids_of_members: uuids_of_members });
//    this.setData(res.data);
    Activity.updateLocalMany(res.data)
  };

  // Get the labels of this activity
  getLabels = () => {
    if (!this.data.uuids_of_labels)
      return [];
    const labels = this.data.uuids_of_labels.map(uuid => ActivityLabel.getByUuid(uuid));
    return labels;
  };

  // Set the labels of this activity
  setLabels = async (uuids_of_labels) => {
    const api = new PmApi();
    let res = await api.put(`activities/${this.uuid}/labels`, { uuids_of_labels: uuids_of_labels });
    this.setData(res.data);
  };

  // Archive the activity
  archive = async () => {
    const api = new PmApi();
    let res = await api.post(`activities/${this.uuid}/archive`);
    Activity.updateLocalMany(res.data)
    return res;
  };

  // Un-archive the activity
  unarchive = async () => {
    const api = new PmApi();
    let res = await api.post(`activities/${this.uuid}/unarchive`);
    Activity.updateLocalMany(res.data)
    return res;
  };

  // Return a "stated" icon for this activity
  getTypeStateIcon = () => {
    const _type = this.data.type;
    const state = this.data.state;

    if (this.data.type in Activity.TYPES_STATES)
      return Activity.TYPES_STATES[_type][state];
    else
      return Activity.TYPES[_type]['icon'];
  }

  // Return icon and title
  getIconAndTitle = () => {
    return <><span className="me-2">{this.getTypeStateIcon()}</span>{this.data.title}</>;
  }

  // Return class for activity background
  getBgClass = () => {
    switch (this.data.bg_type) {
      default:
      case Activity.BG_TYPE_NONE:
        return "bg-none";
      case Activity.BG_TYPE_SOLID:
        return `bg-pre bg-pre-solid-${this.data.bg_value}`;
      case Activity.BG_TYPE_GRADIENT:
        return `bg-pre bg-pre-gradient-${this.data.bg_value}`;
      case Activity.BG_TYPE_UNSPLASH:
        return "bg-img";
    }
  }

  // Return URL of activity background image
  getBgImage = () => {
    if (this.data.bg_type === Activity.BG_TYPE_UNSPLASH)
      return this.data.bg_value + "&fm=jpg&q=80&w=1920";
    else
      return null;
  }

  // Return style for activity background image
  getBgStyle = () => {
    const imageUrl = this.getBgImage();
    if (imageUrl)
      return {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center", 
      }
    else 
      return {};
  }
}


import React from "react";
import {
  Button,
} from "reactstrap";
import ActivitySettingsDrawer from './ActivitySettingsDrawer';


/**
 * Props:
 *    activities
 */
class ActivityPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsOpen: false,
    }

    this.activitiesViewRef = React.createRef();
  }

  // Called when component is created
  componentDidMount = () => {
    if (this.props.activities.hasParent()) {
      // Register for notifications by the BackendObject (Activity)
      const activity = this.props.activities.getParent();
      activity.registerListener(this);
    }
  }

  // // Called when component updates
  // componentDidUpdate = async (prevProps) => {
  //   if (this.props.activity !== prevProps.activity) {
  //     prevProps.activity.unregisterListener(this);
  //     this.props.activity.registerListener(this);
  //   }
  // }  

  // Invoked immediately before this component is unmounted and destroyed
  componentWillUnmount = () => {
    if (this.props.activities.hasParent()) {
      // Register for notifications by the BackendObject (Activity)
      const activity = this.props.activities.getParent();
      activity.unregisterListener(this);
    }
  }

  // Called when the related BackendObject (Activity) has been updated
  backendObjectUpdated = (entry) => {
    this.setState({});
  }

  renderPreTools = () => {
    return null;
  }

  renderCustomTools = () => {
    return null;
  }

  renderMain = () => {
    return null;
  }

  startCreateActivity = () => {
    console.debug("ActivityPane.startCreateActivity()");
    if (this.activitiesViewRef && this.activitiesViewRef.current)
      return this.activitiesViewRef.current.createNewAtStart();
  }

  openSettings = () => {
    this.setState({ settingsOpen: true });
  }

  closeSettings = () => {
    this.setState({ settingsOpen: false });
  }

  render = () => {
    // Background (image, color or gradient)
    let bgClass = "";
    let bgStyle = {};
    if (this.props.activities.hasParent()) {
      const activity = this.props.activities.getParent();
      bgClass = activity.getBgClass();
      bgStyle = activity.getBgStyle();
    }

    return (
      <>
        <div style={bgStyle} className={`head-main ${bgClass}`}>
          <div className="head">
            <div className="activity-header">

              {this.props.preTools}

              <div className="d-flex ms-auto">
                {this.props.activities.canAddNew() && (
                  <Button 
                    color="success"
                    size="sm"
                    title="Create new activity"
                    className="me-2 ms-auto"
                    onClick={this.startCreateActivity}>
                    <i className="bi bi-plus"></i>
                  </Button>
                )}

                {this.renderCustomTools()}

                {this.props.otherTools}

                {this.props.activities.isSortable() && (
                  <Button
                    size="sm"
                    color="secondary"
                    className="me-2"
                    disabled
                    title="Activities in this list are sortable.">
                    <i className="bi bi-arrow-down-up"></i>
                  </Button>
                )}

                {this.props.activities.hasParent() && (
                  <Button
                    size="sm"
                    color="secondary"
                    onClick={this.openSettings}
                    title="Open settings.">
                    <i className="bi bi-three-dots-vertical"></i>
                  </Button>
                )}
              </div>
            </div>
          </div>
          {/* {this.props.rootActivity && (
          <div className="head">
            <div className="activity-header">
              <ActivityHeader
                  activity={this.props.rootActivity}
                />
            </div>
          </div>
          )} */}
          {this.renderMain()}
        </div>
        {this.props.activities.hasParent() && (
          <ActivitySettingsDrawer 
            isOpen={this.state.settingsOpen}
            activity={this.props.activities.getParent()}
            onCloseClick={this.closeSettings}
          />
        )}
      </>
    )
  }
}


export default ActivityPane;
import PmApi from '../backend/PmApi';
import Activity from '../backend/Activity';
import BackendObject from '../backend/BackendObject';
import PmUserIcon from '../components/PmUserIcon';
import { isEqual, cloneDeep } from 'lodash';


export default class PmUser extends BackendObject {
  // Maps uuid to PmUser object
  static uuidToPmUser = new Map();

  // Get an PmUser by its uuid
  static getByUuid(uuid) {
    if (PmUser.uuidToPmUser.has(uuid))
      return PmUser.uuidToPmUser.get(uuid);
    else 
      return null;
  }

  // Save a PmUser by its uuid
  static async setByUuid(uuid, member) {
    PmUser.uuidToPmUser.set(uuid, member);
  }

  // Create a new local copy of PmUser or update the existing local copy
  static updateLocal = (uuid, data) => {
    if (PmUser.uuidToPmUser.has(uuid)) {
      let member = PmUser.uuidToPmUser.get(uuid);
      member.uuid = uuid;
      member.data = data;
      return member;
    }
    else {
      let member = new PmUser(uuid, data);
      PmUser.setByUuid(uuid, member);
      return member;
    }
  }

  // Update local instances of PmUser
  static updateLocalMany = (data) => {
    data.map(item => {
        PmUser.updateLocal(item.uuid, item);
    });
  }

  // Load all members from backend
  static loadAll = async () => {
    try {
      const api = new PmApi();
      const res = await api.get("pmusers");
      this.updateLocalMany(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  // Return all members
  static getAll = () => {
    return PmUser.uuidToPmUser;
  }

  // Load a single member; use the cached copy if available.
  static load = async (uuid) => {

    let member = PmUser.getByUuid(uuid);
    if (member)
      return member;

    const api = new PmApi();
    let res = await api.get(`pmusers/${uuid}`);
    // TODO: gestire i 404!
    return PmUser.updateLocal(res.data.uuid, res.data);
  };

  constructor(uuid, data) {
    super();
    this.uuid = uuid;
    this.data = data;
  }

  // Update the data fields
  setData = (data) => {
    if (isEqual(data, this.data)) // lodash
        // No change
        return;

    this.data = cloneDeep(data);
    this.notifyRegisteredListeners();
  }

  // Refresh contents of this PmUser
  refresh = async () => {
    console.debug("PmUser.refresh()");

    this.data = {};
    this.subactivities = null;

    const api = new PmApi();
    try {
      const res = await api.get(`pmusers/${this.uuid}`);
      this.setData(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Set user initials
   */
  setInitials = async (initials) => {
    const api = new PmApi();
    let res = await api.patch(`pmusers/${this.uuid}`, { initials: initials });
    this.setData(res.data);
    return res;
  }

  // Update all user data
  update = async (data) => {
    const api = new PmApi();
    let res = await api.patch(
      `pmusers/${this.uuid}`, 
      data
    );

    this.setData(res.data);
    return res;
  }

  // Change the avatar
  setAvatar = async (data) => {
    const api = new PmApi();
    api.setFormDataEncoding();

    let res = await api.patch("avatar", data);
    this.setData(res.data);
    return res;
  }

  /**
   * @returns [array(Activity)] Workspaces the user has access to
   */
  getWorkspaces = async () => {
    const api = new PmApi();
    let res = await api.get(`workspaces`);
    return Activity.updateLocalMany(res.data);
  }

  /**
   * @returns [Activity] User's main workspace
   */
  getWorkspace = async () => {
    const uuid = this.data.uuid_of_workspace;
    return await Activity.load(uuid);
  }

  // Return icon and title
  getIconAndTitle = (size) => {
    if (!size)
      size = "sm";
    return <><PmUserIcon className="me-2" size={size} pmUser={this} />{this.data.full_name}</>;
  }
}


/**
 * An object which resides on the backend and (possibly) has a visual representation on the
 * frontend. Listeners can register against this object to get notified whenever the
 * component updates.
 * 
 * @author mmanzato
 */
export default class BackendObject {
  constructor() {
    this.registeredListeners = [];
  }

  // Register `listener` for updates to this backend component
  registerListener = (listener) => {
    if (!this.registeredListeners.includes(listener))
      this.registeredListeners.push(listener);
  };

  // Unregister `listener` for updates to this backend component
  unregisterListener = (listener) => {
    if(this.registeredListeners.includes(listener))
      this.registeredListeners.splice(this.registeredListeners.indexOf(listener), 1);    
  };

  /** Notify all registered listener views that this backend component has updated.
   *  The `backendObjectUpdated` method is called on all registered listeners. 
   */
  notifyRegisteredListeners = () => {
    this.registeredListeners.forEach((listener) => listener.backendObjectUpdated(this));
  };
}


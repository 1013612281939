import PmApi from '../backend/PmApi';
import {FormField, FormSpinner, FormNonFieldErrors} from './forms';
import React from 'react';
import Drawer from 'react-modern-drawer'
import {
  Button,
  Input,
  FormGroup,
  Label,
  FormFeedback,
} from "reactstrap";
import { globalApp } from './App';
import PmUserCard from './PmUserCard';
import AvatarInput from './AvatarInput';


/**
 * User profile editing. as a drawer.
 * 
 * Props:
 *    pmUser      PmUser object
 *    isOpen      
 *    onCloseClick
 */
class PmUserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainData: PmApi.makeFormData({
        first_name: this.props.pmUser.data.first_name,
        last_name: this.props.pmUser.data.last_name,
      }),

      changePassword: PmApi.makeFormData({
        old_password: "",
        password: "",
        password2: "",
      }),
    }

    this.avatarInputRef = React.createRef();
  }

  onMainDataSubmit = async () => {
    const api = new PmApi();
    let res = await api.formCall("patch", this, "mainData", "profile");
    if (res)
      this.props.pmUser.setData(res.data);
  }

  onAvatarSubmit = async () => {
    const ad = await this.avatarInputRef.current.getData();
    let data = {
      photo: ad.croppedImage,
      avatar_scale: ad.scale,
      avatar_x: ad.x,
      avatar_y: ad.y,
    }
    if (ad.image instanceof File) {
      data['source_photo'] = ad.image
    }

    this.props.pmUser.setAvatar(data);
  }

  onPasswordSubmit = () => {
    const api = new PmApi();
    api.formCall("put", this, "changePassword", "change-password");
  }

  render = () => {
    const w = 150;
    const b = 15;
    return (
      <Drawer
        open={this.props.isOpen}
        // onClose={toggleDrawer}
        direction='right'
        size={"500px"}
        className='card drawer'
      >
        <div className="card-header drawer-header">
          <PmUserCard pmUser={this.props.pmUser}/>
          <div className="bi bi-x close-button" title="Close" onClick={this.props.onCloseClick}></div>
        </div>

        <div className="card-body drawer-body">

          <div className="accordion accordion-flush" id="userProfileAccordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  <i className="bi bi-person me-2"></i>
                  <span className="me-2">Edit main user data</span>
                  &nbsp;&nbsp;
                  <FormSpinner
                    parent={this}
                    groupName="mainData"
                  />
                </button>
              </h2>
              <div id="flush-collapseOne" className="accordion-collapse collapse" aria-expanded="true" aria-labelledby="flush-headingOne" data-bs-parent="#userProfileAccordion">
                <div className="accordion-body">
                  <FormNonFieldErrors
                    parent={this}
                    groupName="mainData"
                  />
                  <FormField
                    type="text"
                    label="Username"
                    name="username"
                    value={this.props.pmUser.data.username}
                    disabled={true}
                  />
                  <FormField
                    type="text"
                    label="E-mail"
                    name="email"
                    value={this.props.pmUser.data.email}
                    disabled={true}
                  />
                  <FormField
                    type="text"
                    label="First Name"
                    name="first_name"
                    parent={this}
                    groupName="mainData"
                  />
                  <FormField
                    type="text"
                    label="Last Name"
                    name="last_name"
                    parent={this}
                    groupName="mainData"
                  />

                  <Button 
                    color="primary" 
                    disabled={this.state.mainData.submitting} 
                    onClick={this.onMainDataSubmit}>
                      Update
                  </Button>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  <i className="bi bi-person-circle me-2"></i>
                  Edit Avatar
                </button>
              </h2>
              <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#userProfileAccordion">
                <div className="accordion-body">

                  <FormGroup>
                    <AvatarInput 
                      ref={this.avatarInputRef}
                      imageUrl={this.props.pmUser.data.source_photo_url}
                      scale={this.props.pmUser.data.avatar_scale}
                      x={this.props.pmUser.data.avatar_x}
                      y={this.props.pmUser.data.avatar_y}
                    />
                  </FormGroup>

                  <Button color="primary" onClick={this.onAvatarSubmit}>Update</Button>

                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                  <i className="bi bi-lock me-2"></i>
                  <span className="me-2">Change password</span>
                  &nbsp;&nbsp;                  
                  <FormSpinner
                    parent={this}
                    groupName="changePassword"
                  />
                </button>
              </h2>
              <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#userProfileAccordion">
                <div className="accordion-body">
                  <FormNonFieldErrors
                    parent={this}
                    groupName="changePassword"
                  />

                  <FormField
                    type="passwordEye"
                    label="Old password"
                    name="old_password"
                    parent={this}
                    groupName="changePassword"
                    />
                  <FormField
                    type="passwordEye"
                    label="New password"
                    name="password"
                    parent={this}
                    groupName="changePassword"
                    />
                  <FormField
                    type="passwordEye"
                    label="Confirm new password"
                    name="password2"
                    parent={this}
                    groupName="changePassword"
                    />

                  <Button 
                    color="primary" 
                    disabled={this.state.changePassword.submitting} 
                    onClick={this.onPasswordSubmit}>
                      Update
                  </Button>
                </div>
              </div>
            </div>
          {globalApp.state.pmUser.data.can_access_admin && (
            <div className="accordion-item">
              <a className="accordion-link" href="/backend/admin/" target="pm3-admin">
                <i className="bi bi-gear me-2"></i>
                Admin site
              </a>
            </div>
          )}
            <div className="accordion-item">
              <div className="accordion-link" onClick={globalApp.logoutUser}>
                <i className="bi bi-box-arrow-right me-2"></i>
                Logout
              </div>
            </div>

          </div>
        </div>
      </Drawer>
    );
  }
}

export default PmUserProfile;

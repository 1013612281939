import React from 'react';
import PmApi from '../backend/PmApi';
import Activity from '../backend/Activity';
import {FormField, FormSpinner, FormNonFieldErrors} from './forms';
import Drawer from 'react-modern-drawer';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { globalApp } from './App';



/**
 * Set a colored background.
 * 
 * Props:
 *    activity
 *    selectBg
 */
class ColoredBackground extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render = () => {
    const solidColors = Array.from({length: 9}, (_, i) => i + 1);
    const gradientColors = Array.from({length: 6}, (_, i) => i + 1);
    const data = this.props.activity.data;

    return (
      <div className="background-selection">
        <p><b>None:</b></p>
        <div className="background-items">
            <div className="background-item bg-none"
                  onClick={() => this.props.selectBg(Activity.BG_TYPE_NONE, "--")}>
              {(data.bg_type === Activity.BG_TYPE_NONE) && (
                <span className="check"><i className="bi bi-check-lg"></i></span>
              )}
            </div>
        </div>
        <br/>
        <p><b>Solid colors:</b></p>
        <div className="background-items">
          {solidColors.map((n) => 
            <div key={n} 
                  className={`background-item bg-pre bg-pre-solid-${n}`}
                  onClick={() => this.props.selectBg(Activity.BG_TYPE_SOLID, n)}>
              {(data.bg_type === Activity.BG_TYPE_SOLID) && (data.bg_value == n) && (
                <span className="check"><i className="bi bi-check-lg"></i></span>
              )}
            </div>
          )}
        </div>
        <br/>
        <p><b>Shades:</b></p>
        <div className="background-items">
          {gradientColors.map((n) => 
            <div key={n} 
                  className={`background-item bg-pre bg-pre-gradient-${n}`}
                  onClick={() => this.props.selectBg(Activity.BG_TYPE_GRADIENT, n)}>
              {(data.bg_type === Activity.BG_TYPE_GRADIENT) && (data.bg_value == n) && (
                <span className="check"><i className="bi bi-check-lg"></i></span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}


/**
 * Search a background image with Unsplash.
 * 
 * Props:
 *    activity
 *    selectBg
 */
class UnsplashBackground extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: PmApi.makeFormData({ searchText: "" }),
      totalResults: false,
      unSplashResults: [],
      hasMoreResults: false,
      resultPage: 0,
    }
  }

  searchImages = async (event) => {
    event.preventDefault();
    this.setState({
      totalResults: false,
      unSplashResults: [],
      hasMoreResults: false,
      resultPage: 0,
    });
    this.fetchMoreResults();
  }

  fetchMoreResults = async () => {
    const searchText = this.state.search.data.searchText;
    const page = this.state.resultPage + 1;
    const api = new PmApi();
    let res = await api.get(`unsplash-search-photos`, { q: searchText, page: page});
    this.setState({
      unSplashResults: this.state.unSplashResults.concat(res.data.results),
      totalResults: res.data.total,
      hasMoreResults: res.data.total_pages > page,
      resultPage: page,
    });
  }

  render = () => {
    return (
      // <div className="background-selection">
      <div className="background-selection">
        <form className="background-search" onSubmit={this.searchImages}>
          <FormNonFieldErrors
            parent={this}
            groupName="search"
          />
          <FormField
            type="text"
            label="Search text"
            name="searchText"
            parent={this}
            groupName="search"
            rightButtonContent={<i className="bi bi-search"></i>}
            rightButtonType="submit"
          />
          {(this.state.totalResults !== false) && (
          <p><small>{this.state.totalResults} photos found.</small></p>
          )}
        </form>
      {(this.state.unSplashResults.length > 0) && (
          <InfiniteScroll
            dataLength={this.state.unSplashResults.length}
            next={this.fetchMoreResults}
            hasMore={this.state.hasMoreResults}
            height={600}
            loader={<p><small>Loading...</small></p>}
            endMessage={<p><small>No more photos.</small></p>}>
            <div className="background-items search-results">
            {this.state.unSplashResults.map((result) =>
              <div key={result.id} 
                    className="background-item"
                    style={{ backgroundImage: `url(${result.urls.small})` }}
                    onClick={() => this.props.selectBg(Activity.BG_TYPE_UNSPLASH, result.urls.raw)}>
              </div>
            )}
          </div>
          </InfiniteScroll>
      )}
      </div>
    );
  }

}


/**
 * Drawer with activity settings.
 * 
 * Props:
 *    activity          The activity
 */
class BackgroundSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePaneId: null,
    }
  }

  setBgPane = (id) => {
    this.setState({ activePaneId: id });
  }

  // Called when component is created
  componentDidMount = () => {
    const data = this.props.activity.data;
    switch (data.bg_type) {
      case Activity.BG_TYPE_NONE: 
      case Activity.BG_TYPE_SOLID: 
      case Activity.BG_TYPE_GRADIENT:
        this.setBgPane("color");
        break;
      case Activity.BG_TYPE_UNSPLASH:
        this.setBgPane("unsplash");
        break;
    }
    // Register this ActivityView for notifications by the BackendObject (Activity)
    this.props.activity.registerListener(this);
  }

  // Invoked immediately before this component is unmounted and destroyed
  componentWillUnmount = () => {
    // Unregister this ActivityView from notifications by the BackendObject (Activity)
    this.props.activity.unregisterListener(this);
  }

  // Called when the related BackendObject (Activity) has been updated
  backendObjectUpdated = (entry) => {
    this.setState({});
  }

  selectBg = async (bgType, bgValue) => {
    await this.props.activity.setBg(bgType, bgValue);
  }

  render = () => {
    return (
      <>
        <Nav className="compact-tabs bg-light">
          <Nav tabs>
            <NavItem>
              <NavLink
                active={this.state.activePaneId === "color"} 
                onClick={() => this.setBgPane("color")}
                href="#">Color
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={this.state.activePaneId === "unsplash"} 
                onClick={() => this.setBgPane("unsplash")}
                href="#">Unsplash
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={this.state.activePaneId === "custom"} 
                onClick={() => this.setBgPane("custom")}
                href="#">Custom
              </NavLink>
            </NavItem>
          </Nav>         
        </Nav>
        <TabContent activeTab={this.state.activePaneId}>
          <TabPane tabId="color">
            <ColoredBackground 
              activity={this.props.activity} 
              selectBg={this.selectBg} 
            />
          </TabPane>
          <TabPane tabId="unsplash">
            <UnsplashBackground
              activity={this.props.activity} 
              selectBg={this.selectBg} 
            />
          </TabPane>
          <TabPane tabId="custom">
            Custom
          </TabPane>
        </TabContent>
      </>
    );
  }
};


/**
 * Drawer with activity settings.
 * 
 * Props:
 *    activity          The activity
 *    isOpen
 *    onCloseClick
 */
class ActivitySettingsDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePaneId: null,
    }
  }

  setBgPane = (id) => {
    this.setState({ activePaneId: id });
  }

  onPasswordSubmit = () => {
    const api = new PmApi();
    api.formCall("put", this, "changePassword", "change-password");
  }

  render = () => {
    return (
      <Drawer
        open={this.props.isOpen}
        direction='right'
        size={"500px"}
        className='card drawer'
      >
        <div className="card-header drawer-header">
          {this.props.activity.data.title}
          <div className="bi bi-x close-button" title="Close" onClick={this.props.onCloseClick}></div>
        </div>

        <div className="card-body drawer-body">
          {this.props.isOpen && (
            <div className="accordion accordion-flush">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#background-accordion" aria-expanded="false" aria-controls="background-accordion">
                    <i className="bi bi-card-image me-2"></i>
                    <span className="me-2">Change background</span>
                  </button>
                </h2>
                <div id="background-accordion" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#userProfileAccordion">
                  <div className="accordion-body background-settings">
                    <BackgroundSettings activity={this.props.activity} />
                  </div>
                </div>
              </div>
              {/* <div className="accordion-item">
                <div className="accordion-link">
                  <i className="bi bi-gear me-2"></i>
                  Settings
                </div>
              </div> */}
            </div>
          )}
        </div>
      </Drawer>
    );
  }
}

export default ActivitySettingsDrawer;

import React from "react";
import KanbanColumn from "./KanbanColumn";
import ActivityPane from './ActivityPane';
import Activity from '../backend/Activity';

/**
 * Props:
 *      activities (from ActivityPane)
 * 
 *      showCancelled
 *      showCompleted
 *      showArchived
 */
class ActivityKanbanPane extends ActivityPane {

  // constructor(props) {
  //   super(props);
  // }

  renderMain = () => {
    const cols = 4 + (this.props.showCompleted ? 1 : 0) + (this.props.showCancelled ? 1 : 0);
    return (
      <div className="main main-noscroll">
        <div className={`activity-kanban columns-${cols}`}>
          {/* <KanbanColumn 
            parent={this.props.rootActivity} 
            filters={this.props.filters}
            state={Activity.STATE_NEW} 
            title="New" 
          />
          <KanbanColumn 
            parent={this.props.rootActivity}
            filters={this.props.filters} 
            state={Activity.STATE_PLANNED} 
            title="Planned" 
          /> */}
          <KanbanColumn 
            activities={this.props.activities} 
            state={Activity.STATE_TODO} 
            title="New" 
          />
          <KanbanColumn 
            activities={this.props.activities} 
            state={Activity.STATE_ONGOING} 
            title="Ongoing" 
          />
          <KanbanColumn 
            activities={this.props.activities} 
            state={Activity.STATE_ONHOLD} 
            title="On Hold"
          />
          {this.props.showCompleted && (
          <KanbanColumn 
            activities={this.props.activities}
            state={Activity.STATE_COMPLETED}
            title="Completed"
          />
          )}
          {this.props.showCancelled && (
          <KanbanColumn 
            activities={this.props.activities}
            state={Activity.STATE_CANCELLED} 
            title="Cancelled" 
          />
          )}
        </div>
      </div>
    );
  }
}

export default ActivityKanbanPane;

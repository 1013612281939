import React from "react";
import ActivitiesView from './ActivitiesView';
import ActivityPane from './ActivityPane';
import {
  Button,
  ButtonGroup
} from "reactstrap";


/**
 * Props:
 *      activities (from ActivityPane)
 * 
 *      showPrivate
 *      showNonPrioritary
 *      showCancelled
 *      showCompleted
 *      showArchived
 * 
 *      showPositionBadge
 *      showBreadcrumbs
 */
class ActivityListPane extends ActivityPane {

  // Close all the activities
  closeAll = () => {
    this.activitiesViewRef.current.closeAll();
  };

  // Open all the activities
  openAll = () => {
    this.activitiesViewRef.current.openAll();
  };

  renderCustomTools = () => {
    return (
      <ButtonGroup className="me-2" size="sm">
        <Button  
          color="primary"
          onClick={this.closeAll}
          title="Close all">
          <i className="bi bi-arrow-90deg-left"></i>
        </Button>
        <Button  
          color="primary"
          onClick={this.openAll}
          title="Open all">
          <i className="bi bi-arrow-return-right"></i>
        </Button>
      </ButtonGroup>
    );
  }

  renderMain = () => {
    return (
      <div className="main main-scroll">
        <div className="container" >
          <div className="bg" />
          <div className="activity-list">
            <ActivitiesView
              ref={this.activitiesViewRef}
              view={"list"}
              activities={this.props.activities}
              hidePrivate={!this.props.showPrivate}
              hideNonPrioritary={!this.props.showNonPrioritary}
              hideCancelled={!this.props.showCancelled}
              hideCompleted={!this.props.showCompleted}
              showArchived={this.props.showArchived}
              showPositionBadge={this.props.showPositionBadge}
              showBreadcrumbs={this.props.showBreadcrumbs}
            />
          </div>
        </div>
      </div>
    );
  }
}


export default ActivityListPane;
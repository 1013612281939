import React from "react";
import PmUser from '../backend/PmUser';
import { RootActivities, ChildActivities, OwnActivities } from '../backend/Activities';
import ActivitySinglePane from './ActivitySinglePane';
import InlineEditor from "./InlineEditor";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,  
} from "reactstrap";
import { globalApp } from './App'
import { createContext } from 'react';

export const MainPanesContext = createContext(null);


/**
 * Panes for main view.
 * 
 * Props:
 *    default
 *    showPrivate
 *    showNonPrioritary
 *    showCancelled
 *    showCompleted
 *    showArchived
 */
export class MainPanes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      panes: {},
      activePaneId: null,
      workspacesDropdownOpen: false,
      pmUsersDropdownOpen: false,
    };
  }

  toggleWorkspacesDropdown = () => {
    this.setState({ workspacesDropdownOpen: !this.state.workspacesDropdownOpen });
  }

  togglePmUsersDropdown = () => {
    this.setState({ pmUsersDropdownOpen: !this.state.pmUsersDropdownOpen });
  }

  // Called when component mounts
  componentDidMount = async () => {
    const pmUser = globalApp.state.pmUser;
    this.workspaces = await pmUser.getWorkspaces();
    if (this.props.default) {
      this.addCurrentUserWorkspacePane();
    }
    this.setState({ loading: false });
  }

  addCurrentUserWorkspacePane = async () => {
    const pmUser = globalApp.state.pmUser;
    const workspace = await pmUser.getWorkspace();
    this.addActivityPane(workspace);
  }

  addRootWorkspacesPane = async () => {
    const mpe = new RootWorkspacesTabPane(this.props);
    this.addPane(mpe);
  }

  addActivityPane = (workspace) => {
    const mpe = new WorkspaceTabPane(this.props, workspace);
    this.addPane(mpe);
  }

  addCurrentUserAssignedActivitiesPane = async () => {
    const pmUser = globalApp.state.pmUser;
    this.addUserAssignedActivitiesPane(pmUser);
  }

  addUserAssignedActivitiesPane = (pmUser) => {
    const mpe = new AssignedActivitiesTabPane(this.props, pmUser);
    this.addPane(mpe);
  }

  setActivePane = (id) => {
    this.setState({
      activePaneId: id,
    });

    // Hack: Delay fixing editor sizes until re-render is complete
    // See: https://stackoverflow.com/questions/26556436/react-after-render-code
    setTimeout(function () {
      InlineEditor.resizeAllEditors();
    });
  }

  addPane = (mpe) => {
    let newPanes = {...this.state.panes};
    const id = mpe.getId();
    newPanes[id] = mpe;
//    mpe.setMainPanes(this);
    this.setState({
      panes: newPanes,
    });
    this.setActivePane(id);
  }

  closePane = (id) => {
    let newPanes = {...this.state.panes};
    delete newPanes[id];

    this.setState({
      panes: newPanes,
    });

    if (this.state.activePaneId === id) {
      const keys = Object.keys(newPanes);
      if (keys.length > 0)
        this.setActivePane(keys[0]);
    }
  }

  render = () => {

    const pmUsers = PmUser.getAll();

    return (
      <MainPanesContext.Provider value={this}>
        <div className="head-main">
          <div className="head">
            <Nav className="align-items-center compact-tabs bg-dark">

              <Nav tabs>
                {Object.keys(this.state.panes).map((id) => {
                  const pane = this.state.panes[id];
                  return (
                    <NavItem key={id}>
                      <NavLink
                        active={this.state.activePaneId === id} 
                        href="#">
                        <span onClick={() => this.setActivePane(id)} className="nav-item-label">{pane.renderTab(this.props)}</span>
                        <span onClick={() => this.closePane(id)}><i className="bi bi-x ms-2"></i></span>
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>         

                <NavItem className="ms-auto">
                  <Dropdown size="sm" isOpen={this.state.workspacesDropdownOpen} toggle={this.toggleWorkspacesDropdown}>
                    <DropdownToggle caret>
                      <i className="bi bi-columns me-2"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>My workspaces</DropdownItem>
                      <DropdownItem
                        onClick={this.addCurrentUserWorkspacePane}>
                        My workspace
                      </DropdownItem>

                      <DropdownItem divider />

                      <DropdownItem header>Root workspaces</DropdownItem>
                      <DropdownItem
                        onClick={this.addRootWorkspacesPane}>
                        Root workspaces
                      </DropdownItem>

                      <DropdownItem divider />

                      <DropdownItem header>Shared workspaces</DropdownItem>
                    { this.workspaces && this.workspaces.map(workspace => 
                      <DropdownItem
                        key={workspace.data.uuid}
                        onClick={() => this.addActivityPane(workspace)}>
                        {workspace.getIconAndTitle()}
                      </DropdownItem>
                    )}
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>

                <NavItem className="ms-2">
                  <Dropdown size="sm" isOpen={this.state.pmUsersDropdownOpen} toggle={this.togglePmUsersDropdown}>
                    <DropdownToggle caret>
                      <i className="bi bi-person-fill me-2"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={this.addCurrentUserAssignedActivitiesPane}>
                        My activities
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem header>All users</DropdownItem>
                    { pmUsers && [...pmUsers].map(([uuid, pmUser]) => 
                      <DropdownItem
                        key={uuid}
                        onClick={() => this.addUserAssignedActivitiesPane(pmUser)}>
                        {pmUser.getIconAndTitle()}
                      </DropdownItem>
                    )}
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>

            </Nav>
          </div>
          <TabContent className="main main-defaultbg" activeTab={this.state.activePaneId}>
            {Object.keys(this.state.panes).map((id) => {
              const pane = this.state.panes[id];
              return (
                  <TabPane 
                    key={id} 
                    tabId={id} 
                    className="head-main">
                    {pane.renderMain(this.props)}
                  </TabPane>
                );
            })}
          </TabContent>
        </div>
      </MainPanesContext.Provider>
    );
  }
}


/**
 * Generic main page pane (tab).
 */
class MainPane {
  // constructor() {
  //   this.mainPanes = null;
  // }

  // setMainPanes = (mainPanes) => {
  //   this.mainPanes = mainPanes;
  // }

  renderTab = (props) => {
  }

  renderMain = (props) => {
  }
}


/**
 * Workspace main page pane (tab).
 */
class WorkspaceTabPane extends MainPane {
  constructor(props, workspace) {
    super(props);
    this.workspace = workspace;
  }

  getId = () => {
    return 'ws-'+ this.workspace.uuid;
  }

  renderTab = (props) => { 
    return this.workspace.getIconAndTitle();
  }

  getActivities = () => {
    return new ChildActivities(this.workspace);
  }

  renderMain = (props) => {
    return (
      <ActivitySinglePane 
        {...props}
        activities={this.getActivities()}
        showPositionBadge={true}
        showBreadcrumbs={false}
      />
    );    
  }
};


/**
 * Root workspaces main page pane (tab).
 */
class RootWorkspacesTabPane extends MainPane {
  constructor(props) {
    super(props);
  }

  getId = () => {
    return 'ws-root-workspaces';
  }

  renderTab = (props) => { 
    return "Root workspaces";
  }

  getActivities = () => {
    return new RootActivities();
  }

  renderMain = (props) => {
    return (
      <ActivitySinglePane 
        {...props}
        activities={this.getActivities()}
        showPositionBadge={true}
        showBreadcrumbs={false}
      />
    );    
  }
};


/**
 * Pane for assigned activities
 */
class AssignedActivitiesTabPane extends MainPane {
  constructor(props, pmUser) {
    super(props);
    this.pmUser = pmUser;
  }

  getId = () => {
    return 'aa-'+ this.pmUser.uuid;
  }

  renderTab = (props) => { 
    return this.pmUser.getIconAndTitle("xs");
  }

  getActivities = () => {
    return new OwnActivities(this.pmUser);
  }

  renderMain = (props) => {
    return (
      <ActivitySinglePane 
        {...props}
        activities={this.getActivities()}
        showPositionBadge={false}
        showBreadcrumbs={true}
//        mainPanes={this.mainPanes}
      />
    );    
  }
};

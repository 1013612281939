import React from 'react';
import Activity from '../backend/Activity';
import { Menu, Item, Separator, Submenu, contextMenu } from 'react-contexify';
import { ActivityDueDateModal } from './ActivityDueDate';
import { ActivityEstimatedDurationModal } from './ActivityEstimatedDuration';
import { ActivityMembersModal } from './ActivityMembers';
import { ActivityLabelsModal } from './ActivityLabelView';
import { ActivityJournalModal } from './ActivityJournal';


/*
    Props:
        activity
        activityView    
*/
class ActivityContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.contextMenuId = `${this.props.activity.uuid}-ctxmenu`;

    this.dueDateModalRef = React.createRef();
    this.estimatedDurationModalRef = React.createRef();
    this.membersModalRef = React.createRef();
    this.labelsModalRef = React.createRef();
    this.journalModalRef = React.createRef();
  }

  // Show the context menu
  show = (event) => {
    contextMenu.show({event: event, id: this.contextMenuId});
  }

  // Open the modal to edit the activity due date
  handleDueDateClick = (event) => {
    if (this.dueDateModalRef) {
      const modal = this.dueDateModalRef.current;
      if (modal)
        modal.open(event.event);
    }
  }

  // Open the modal to edit the estimated activity duration
  handleEstimatedDurationClick = (event) => {
    if (this.estimatedDurationModalRef) {
      const modal = this.estimatedDurationModalRef.current;
      if (modal)
        modal.open(event.event);
    }
  }

  // Open journal modal
  handleJournalClick = (event) => {
    if (this.journalModalRef) {
      const modal = this.journalModalRef.current;
      if (modal)
        modal.open(event.event);
    }
  }

  // Open/close details
  handleDetailsClick = (event) => {
    console.log(event);
    if (this.props.activityView.toggleIsJournalOpen) {
      this.props.activityView.toggleDetails(event.event);
    }
  }

  // Edit members
  handleMembersClick = (event) => {
    if (this.membersModalRef) {
      const modal = this.membersModalRef.current;
      if (modal)
        modal.open(event.event);
    }
  }

  // Edit labels
  handleLabelsClick = (event) => {
    if (this.labelsModalRef) {
      const modal = this.labelsModalRef.current;
      if (modal)
        modal.open(event.event);
    }
  }

  // Archive activity
  handleArchive = (event) => {
    this.props.activityView.archive();
  }

  // Un-archive activity
  handleUnarchive = (event) => {
    this.props.activityView.unarchive();
  }

  // Make private
  handleMakePrivate = (event) => {
    this.props.activityView.setPrivate(true);
  }

  // Make public
  handleMakePublic = (event) => {
    this.props.activityView.setPrivate(false);
  }

  setActivityState = async (state) => {
    await this.props.activity.setState(state);
  }

  setActivityType = async (type) => {
    await this.props.activity.setType(type);
  }

  setActivityPriority = async (priority) => {
    await this.props.activity.setPriority(priority);
  }

  // Close all the activities
  closeAll = () => {
    this.props.activityView.closeAll();
  };

  // Open all the activities
  openAll = () => {
    this.props.activityView.openAll();
  };

  render = () => {
    const data = this.props.activity.data;
    return (
      <>
        <Menu id={this.contextMenuId}>
          <Submenu label="Set state">
            {Object.keys(Activity.STATES).map((key) => (
              <Item key={key} onClick={(e) => this.setActivityState(key)}>
                <span className="me-2">{Activity.STATES[key].icon}</span>
                {Activity.STATES[key].name}
                {data.state === key && (<i className="bi bi-check ms-2"></i>)}
              </Item>
            ))}
          </Submenu>

          <Submenu label="Set type">
            {Object.keys(Activity.TYPES).map((key) => (
              <Item key={key} onClick={(e) => this.setActivityType(key)}>
                <span className="me-2">{Activity.TYPES[key].icon}</span>
                {Activity.TYPES[key].name}
                {data.type === key && (<i className="bi bi-check ms-2"></i>)}
              </Item>
            ))}
          </Submenu>

          <Submenu label="Set priority">
            {Object.keys(Activity.PRIORITIES).map((key) => (
              <Item key={key} onClick={(e) => this.setActivityPriority(key)}>
                <span className="me-2">{Activity.PRIORITIES[key].icon}</span>
                {Activity.PRIORITIES[key].name}
                {data.priority === key && (<i className="bi bi-check ms-2"></i>)}
              </Item>
            ))}
          </Submenu>

          <Separator />

          <Submenu label="Set child type">
            {Object.keys(Activity.TYPES).map((key) => (
              <Item key={key} onClick={(e) => this.props.activityView.setChildActivitiesType(key)}>
                <span className="me-2">{Activity.TYPES[key].icon}</span>
                {Activity.TYPES[key].name}
              </Item>
            ))}
          </Submenu>

          <Separator />

          <Item onClick={this.handleDueDateClick}>
            <i className="bi bi-calendar me-2"></i>
            Due date...
            {data.due_date && (<span className="ms-2">⦿</span>)}
          </Item>
          <Item onClick={this.handleEstimatedDurationClick}>
            <i className="bi bi-clock me-2"></i>
            Estimated duration...
            {data.estimated_duration && (<span className="ms-2">⦿</span>)}
          </Item>
          <Item onClick={this.handleMembersClick}>
            <i className="bi bi-person me-2"></i>
            Members...
          </Item>
          <Item onClick={this.handleLabelsClick}>
            <i className="bi bi-tag me-2"></i>
            Labels...
          </Item>
          <Item onClick={this.handleJournalClick}>
            <i className="bi bi-journal me-2"></i>
            Journal...
          </Item>

          <Separator />

          <Item onClick={this.handleDetailsClick}>
            {(this.props.activity.getIsOpen() && (
            <><i className="bi bi-dash me-2"></i> Hide details</>
            )) || (
            <><i className="bi bi-plus me-2"></i> Show details</>
            )}
          </Item>

          <Separator />

        {(this.props.activity.data.has_subactivities) && (
          <>
          <Item onClick={this.closeAll}>
            <i className="bi bi-arrow-90deg-left me-2"></i> Close all
          </Item>
          <Item onClick={this.openAll}>
            <i className="bi bi-arrow-return-right me-2"></i> Open all
          </Item>

          <Separator />
          </>
        )}

          <Item onClick={this.props.activityView.handleCreateNewChild}>
            <i className="bi bi-plus-circle me-2"></i> 
            Create sub-activity
          </Item>
          <Item onClick={this.props.activityView.handleDelete}>
            <i className="bi bi-trash me-2"></i>
            Delete
          </Item>

          <Separator />

          {(data.is_private_local && (
          <Item onClick={this.handleMakePublic}>
            <i className="bi bi-archive-fill me-2"></i> Make public
          </Item>
          )) || (
          <Item onClick={this.handleMakePrivate}>
            <i className="bi bi-archive me-2"></i> Make private
          </Item>
          )}
          <Separator />

          {(data.is_archived && (
          <Item onClick={this.handleUnarchive}>
            <i className="bi bi-archive-fill me-2"></i> Unarchive
          </Item>
          )) || (
          <Item onClick={this.handleArchive}>
            <i className="bi bi-archive me-2"></i> Archive
          </Item>
          )}

        </Menu>

        <ActivityDueDateModal 
          ref={this.dueDateModalRef}
          activity={this.props.activity} 
        />

        <ActivityEstimatedDurationModal 
          ref={this.estimatedDurationModalRef}
          activity={this.props.activity} 
        />

        <ActivityMembersModal 
          ref={this.membersModalRef}
          activity={this.props.activity} 
        />

        <ActivityLabelsModal
          ref={this.labelsModalRef}
          activity={this.props.activity} 
        />

        <ActivityJournalModal
          ref={this.journalModalRef}
          activity={this.props.activity} 
          activityView={this} 
        />
      </>
    );
  }
}

export default ActivityContextMenu;

import PmApi from '../backend/PmApi';
import BackendObject from '../backend/BackendObject';
import PmUser from '../backend/PmUser';
import { isEqual, cloneDeep } from 'lodash';


export default class ActivityJournalEntry extends BackendObject {
  static ACTION_NOTE = 1;

  static TYPE_DESCRIPTION = 1;
  static TYPE_UPDATE = 2;
  static TYPE_MEETING_NOTES = 3;
  static TYPE_COMMENT = 4;
  static TYPES = {
    [ActivityJournalEntry.TYPE_DESCRIPTION]: {
      'name': 'Description',
      'icon': <i className="bi bi-justify-left"></i>,
    },
    [ActivityJournalEntry.TYPE_UPDATE]: {
      'name': 'Update',
      'icon': <i className="bi bi-arrow-return-right"></i>,
    },
    [ActivityJournalEntry.TYPE_MEETING_NOTES]: {
      'name': 'Meeting notes',
      'icon': <i className="bi bi-pen"></i>,
    },
    [ActivityJournalEntry.TYPE_COMMENT]: {
      'name': 'Comment',
      'icon': <i className="bi bi-chat-right-text"></i>,
    },
  }  

  // Load all activity journal entries for `activity`
  static loadForActivity = async (activity) => {
    const api = new PmApi();
    let res = await api.get(`activities/${activity.uuid}/journal`);
    let journalEntries = res.data.map(data => new ActivityJournalEntry(data.uuid, data));
    return journalEntries;
  };

  // Create a new activity journal entry for `activity`
  static createForActivity = async (activity, text) => {
    const api = new PmApi();
    const res = await api.post(`activities/${activity.uuid}/journal`, { text: text, type: ActivityJournalEntry.TYPE_COMMENT });
    return new ActivityJournalEntry(res.data.uuid, res.data);
  };

  constructor(uuid, data) {
    super();
    this.uuid = uuid;
    this.data = cloneDeep(data) || {};
    this.deleted = false;
  }

  // Update the data fields
  setData = (data) => {
    if (isEqual(data, this.data)) // lodash
        // No change
        return;

    this.data = cloneDeep(data);
    this.notifyRegisteredListeners();
  }

  // Refresh journal entry contents from backend
  refresh = async () => {
    this.data = {};
    const api = new PmApi();
    try {
      const res = await api.get(`journal/${this.uuid}`);
      this.setData(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  // Change the journal entry text
  setText = async (text) => {
    const api = new PmApi();
    let res = await api.patch(`journal/${this.uuid}`, { text: text });
    this.setData(res.data);
    return res;
  }

  // Change the journal entry type
  setType = async (type) => {
    const api = new PmApi();
    let res = await api.patch(`journal/${this.uuid}`, { type: type });
    this.setData(res.data);
    return res;
  }

  // Change the journal is_sticky flag
  setSticky = async (sticky) => {
    const api = new PmApi();
    let res = await api.patch(`journal/${this.uuid}`, { is_sticky: sticky });
    this.setData(res.data);
    return res;
  }

  // Delete this journal entry
  delete = async () => {
    const api = new PmApi();
    await api.delete(`journal/${this.uuid}`);
    this.deleted = true;
  };

  // Return the author of this journal entry
  getAuthor = () => {
    return PmUser.getByUuid(this.data.uuid_of_author);
  }

  // Return an icon for the type of this activity journal entry
  getTypeIcon = () => {
    const _type = this.data.type;

    if (this.data.type in ActivityJournalEntry.TYPES)
      return ActivityJournalEntry.TYPES[_type]['icon'];
    else
      return <></>;
  }
}


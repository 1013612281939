import React from "react";
import ActivityJournalEntry from '../backend/ActivityJournalEntry';
import HtmlEditor from "./HtmlEditor";
import Moment from 'react-moment';
import {
  Button,
} from "reactstrap";
import PmUserIcon from "./PmUserIcon";
import { Menu, Item, contextMenu } from 'react-contexify';


/**
 * Props:
 *    entry
 *    
 */
class ActivityJournalEntryView extends React.Component {
  constructor(props) {
    super(props);
    this.htmlEditorRef = React.createRef();
    this.contextMenuId = `${props.entry.uuid}-ctxmenu`;
    this.state = {
      large: false,
    }
  }

  // Called when component is created
  componentDidMount = () => {
    // Register this ActivityView for notifications by the BackendObject (Activity)
    this.props.entry.registerListener(this);
  }

  // Invoked immediately before this component is unmounted and destroyed
  componentWillUnmount = () => {
    // Unregister this ActivityView from notifications by the BackendObject (Activity)
    this.props.entry.unregisterListener(this);
  }

  // Called when the related BackendObject (Activity) has been updated
  backendObjectUpdated = (entry) => {
    this.setState({});
  }

  // Enlarge/reduce text area
  toggleLarge = () => {
    this.setState({ large: !this.state.large });
  }

  // Show the context menu
  handleShowContextMenu = (event) => {
    contextMenu.show({event: event, id: this.contextMenuId});
  }

  handleTextChange = async () => {
    const text = this.htmlEditorRef.current.getContent();
    if (text !== null) {
      await this.props.entry.setText(text);
    }
  }

  handleDelete = async () => {
    if (!this.props.entry)
      return;

    if (window.confirm("Do you really want to delete this entry?")) {
      await this.props.entry.delete();
      if (this.props.onDeleted)
        this.props.onDeleted();
    }
  }

  toggleSticky = async () => {
    if (!this.props.entry)
      return;

    await this.props.entry.setSticky(!this.props.entry.data.is_sticky);
  }

  render = () => {
    const author = this.props.entry ? this.props.entry.getAuthor() : null;
    const text = this.props.entry ? this.props.entry.data.text : "";

    return (
      <div className="entry">
        <div 
          className="entry-side"
        >
          <div className="side-tool" onContextMenu={this.handleShowContextMenu}> 
            {this.props.entry.getTypeIcon()}
          </div>

          <div className="side-tool side-tool-hide" onClick={this.toggleLarge} > 
            <i className={this.state.large ? "bi bi-fullscreen-exit" : "bi bi-fullscreen"}></i>
          </div>
        </div>
        <Menu id={this.contextMenuId}>
        {Object.keys(ActivityJournalEntry.TYPES).map((key) => (
          <Item key={key} onClick={(e) => this.props.entry.setType(key)}>
            <span className="me-2">{ActivityJournalEntry.TYPES[key].icon}</span>
            {ActivityJournalEntry.TYPES[key].name}
            {this.props.entry.data.type === key && (<i className="bi bi-check ms-2"></i>)}
          </Item>
        ))}
        </Menu>

        <div 
          className={`entry-text ${this.state.large ? 'entry-text-large' : 'entry-text-small'}`}
          >
          <HtmlEditor
            ref={this.htmlEditorRef}
            value={text}
            startStatic={true}
            onChange={this.handleTextChange}
          />
        </div>
        <div className="entry-info">
          {author && (
          <div className="entry-author">
            <PmUserIcon pmUser={author} size="sm" className="me-2" />
            <b className="me-2">{author.data.full_name}</b>
            <Moment format="YYYY-MM-DD hh:mm">{this.props.entry.data.last_updated_on}</Moment>
          </div>
          )}
          <div className="entry-actions">
            <span className="action me-2" onClick={this.handleDelete}>
              <i className="bi bi-trash"></i>
            </span>
            {(this.props.entry.data.is_sticky && ( 
            <span className="action sticky" onClick={this.toggleSticky}>
              <i className="bi bi-pin"></i>
            </span>
            )) || (
            <span className="action" onClick={this.toggleSticky}>
              <i className="bi bi-pin-angle"></i>
            </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}



/**
 * Props:
 *  activity
 */
export class ActivityJournalEntries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      journalEntries: [],
    }
  }

  componentDidMount = async () => {
    await this.refreshJournal();
  }

  handleAdd = async () => {
    const entry = await ActivityJournalEntry.createForActivity(this.props.activity, "Enter activity text");
    this.refreshJournal();
  };

  refreshJournal = async () => {
    const journalEntries = await ActivityJournalEntry.loadForActivity(this.props.activity);
    this.setState({ 
      journalEntries: journalEntries,
    });
  };

  render = () => {
    const data = this.props.activity.data;

    return (
      <div className="journal">
        <div className="entries">
          {this.state.journalEntries.map(entry => (
            <ActivityJournalEntryView 
              key={entry.uuid}
              activity={this.props.activity}
              entry={entry}
              onDeleted={this.refreshJournal}
            />
          ))}
        </div>

        {/* <div className="actions">
          <span className="action me-2" onClick={this.handleAdd} title="Add new journal entry">
            <i className="bi bi-plus-circle"></i>
          </span>
        </div> */}
      </div>
    );
  }
}
import React from "react";
import Page from "./Page";
import PmUserIcon from "./PmUserIcon";
//import AppContext from './AppContext';
import SearchBox from './SearchBox';
import PmUser from '../backend/PmUser';
import ActivityLabel from "../backend/ActivityLabel";
import PmUserProfile from "../components/PmUserProfile";

import { withRouter } from "./utils";
import {
  Button,
  ButtonGroup,

  Nav,
  NavItem,
  NavLink,

} from "reactstrap";
import { globalApp } from './App';
import { MainPanes } from './MainPanes';


// The global MainPage singleton
export var globalMainPage = null;


/**
 * Main page
 */
class _MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showPrivate: true, 
      showNonPrioritary: true,
      showCompleted: true,
      showCancelled: true,
      showArchived: false,          // Show (or hide) archived activities
      workspace: null,
      splitView: false,
      isUserProfileOpen: false,
    };

    globalMainPage = this;
    this.workspacesPageRef = React.createRef();
    this.mainPanesRef = React.createRef();
    this.mainPanesRef2 = React.createRef();
  }

  // Called when component mounts
  componentDidMount = async () => {
    await PmUser.loadAll();
    await ActivityLabel.loadAll();
    const pmUser = globalApp.state.pmUser;
    this.workspaces = await pmUser.getWorkspaces();
    this.setState({loading: false});
  }

  toggleNonPrioritary = () => {
    this.setState({ showNonPrioritary: !this.state.showNonPrioritary });
  };

  togglePrivate = () => {
    this.setState({ showPrivate: !this.state.showPrivate });
  };

  toggleCompleted = () => {
    this.setState({ showCompleted: !this.state.showCompleted });
  };

  toggleCancelled = () => {
    this.setState({ showCancelled: !this.state.showCancelled });
  };

  toggleArchived = () => {
    this.setState({ showArchived: !this.state.showArchived });
  };

  openUserProfile = () => {
    this.setState({ isUserProfileOpen: true });
  };

  closeUserProfile = () => {
    this.setState({ isUserProfileOpen: false });
  };

  openSplit = () => {
    this.setState({ splitView: true });
  }

  closeSplit = () => {
    this.setState({ splitView: false });
  }

  goToActivity = async (activity) => {
    let chain = await activity.getChain();
    console.log(chain);

    let workspace = await activity.getWorkspace();
    this.mainPanesRef.current.addActivityPane(workspace);
  }

  render = () => {

    const pmUsers = PmUser.getAll();

    return <Page 
      mainMenu={
        <Nav navbar className=" me-auto mb-2 mb-lg-0 flex-grow-1">
          <NavItem>
            <NavLink>Activities</NavLink>
          </NavItem>
        </Nav>
      }
      searchBox={
        <div className="ms-auto">
          <SearchBox />
        </div>
      }
      customTopBar={
        <div className="ms-auto me-2">
          <ButtonGroup className="me-2" size="sm">
            <Button
              className="nofocus"
              color={this.state.splitView ? "secondary" : "primary"}
              onClick={this.closeSplit}
              active={!this.state.splitView}>
              <i className="bi bi-square"></i>
            </Button>
            <Button
              className="nofocus"
              color={this.state.splitView ? "primary" : "secondary"}
              onClick={this.openSplit}
              active={this.state.splitView}>
              <i className="bi bi-layout-split"></i>
            </Button>
          </ButtonGroup>
          <ButtonGroup className="me-2" size="sm">
            <Button
              className="nofocus"
              color={this.state.showPrivate ? "primary" : "secondary"}
              onClick={this.togglePrivate}
              active={this.state.showPrivate}
              title={this.state.showPrivate ? "Hide private activities" : "Show private activities"}>
              <i className="bi bi-eye-fill"></i>
            </Button>
            <Button
              className="nofocus"
              color={this.state.showNonPrioritary ? "primary" : "secondary"}
              onClick={this.toggleNonPrioritary}
              active={this.state.showNonPrioritary}
              title={this.state.showNonPrioritary ? "Hide non prioritary activities" : "Show non prioritary activities"}>
              <i className="bi bi-arrow-bar-down"></i>
            </Button>
            <Button
              className="nofocus"
              color={this.state.showCompleted ? "primary" : "secondary"}
              onClick={this.toggleCompleted}
              active={this.state.showCompleted}
              title={this.state.showCompleted ? "Hide completed activities" : "Show completed activities"}>
              <i className="bi bi-check"></i>
            </Button>
            <Button
              className="nofocus"
              color={this.state.showCancelled ? "primary" : "secondary"}
              onClick={this.toggleCancelled}
              active={this.state.showCancelled}
              title={this.state.showCancelled ? "Hide cancelled activities" : "Show cancelled activities"}>
              <i className="bi bi-x"></i>
            </Button>
          </ButtonGroup>
          <Button
            className="nofocus"
            size="sm"
            color={this.state.showArchived ? "primary" : "secondary"}
            onClick={this.toggleArchived}
            active={this.state.showArchived}
            title={this.state.showArchived ? "Hide archived activities" : "Show archived activities"}>
            <i className="bi bi-archive"></i>
          </Button>
        </div>
      }      
      userMenu={
      <>
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <span className="nav-link" onClick={this.openUserProfile}>
              <PmUserIcon size="md" border={false} pmUser={globalApp.state.pmUser} />
            </span>
          </li>                    
        </ul>

        <PmUserProfile 
          isOpen={this.state.isUserProfileOpen} 
          pmUser={globalApp.state.pmUser} 
          onCloseClick={this.closeUserProfile} 
          />
      </>
      }
      main={!this.state.loading && (
        <main className="main main-paned">
          <div className="pane">
            <MainPanes 
              ref={this.mainPanesRef}
              default={true}
              showArchived={this.state.showArchived}
              showCompleted={this.state.showCompleted}
              showCancelled={this.state.showCancelled}
              showPrivate={this.state.showPrivate}
              showNonPrioritary={this.state.showNonPrioritary}
            />
          </div>
          <div className={`gap ${!this.state.splitView ? "hide" : ""}`} />
          <div className={`pane ${!this.state.splitView ? "hide" : ""}`} >
            <MainPanes 
              ref={this.mainPanesRef2}
              showArchived={this.state.showArchived}
              showCompleted={this.state.showCompleted}
              showCancelled={this.state.showCancelled}
              showPrivate={this.state.showPrivate}
              showNonPrioritary={this.state.showNonPrioritary}
            />
          </div>
        </main>
      )}
      {...this.props}
    />
  }
}

const MainPage = withRouter(_MainPage);

export default MainPage;

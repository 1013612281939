import React from 'react';


/**
 * The icon for a PmUser object.
 * 
 * Props:
 *    pmUser      PmUser object
 *    size        xs|sm|md|lg|(none - defaults to "sm")
 *    selected    
 *    border      true (default)|false
 *    className   Additional CSS classes
 *    onClick     Callback on click
 */
class PmUserIcon extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render = () => {
    const data = this.props.pmUser.data;
    return (
      <span 
        className={`pmuser-icon ${this.props.size ? this.props.size : "sm"} ${this.props.border===false ? "" : "bordered"} ${this.props.selected ? 'selected' : ''} ${this.props.className}`} 
        title={data.full_name}
        onClick={this.props.onClick}
      >
        {(data.avatar_url && (
        <img alt="" className="pmuser-avatar" src={data.avatar_url} />
        )) || (
        <span className="pmuser-initials">{data.initials}</span>
        )}
      </span>
      )
  }
}

export default PmUserIcon;

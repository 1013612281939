import React from "react";
import Activity from '../backend/Activity';



/**
 * Props:
 *    activity
 *    activityView
 *    compact
 *    show
 */
class ActivityDetails extends React.Component {
  constructor(props) {
    super(props);
  } 

  render = () => {
    const data = this.props.activity.data;
    return (
      <>
        {(this.props.activity.data.is_private && this.props.activity.data.is_private_local && (
          <span className=""><i className="bi bi-eye-fill"></i></span>
        )) || (this.props.activity.data.is_private && !this.props.activity.data.is_private_local && (
          <span className=""><i className="bi bi-eye-fill" style={{color: "gray"}}></i></span>
        )) }

        {(this.props.activity.data.is_archived && this.props.activity.data.is_archived_local && (
          <span className=""><i className="bi bi-archive-fill"></i></span>
        )) || (this.props.activity.data.is_archived && !this.props.activity.data.is_archived_local && (
          <span className=""><i className="bi bi-archive-fill" style={{color: "gray"}}></i></span>
        )) }

        {/* {((this.props.activity.data.priority === Activity.PRIORITY_HIGH) && (
          <span className={`priority priority-high ${this.props.activity.data.priority_local == Activity.PRIORITY_NORMAL ? 'inherited' : ''}`}><span>🠱</span></span>
        )) || ((this.props.activity.data.priority === Activity.PRIORITY_LOW) && (
          <span className={`priority priority-low ${this.props.activity.data.priority_local == Activity.PRIORITY_NORMAL ? 'inherited' : ''}`}><span>🠳</span></span>
        ))} */}
      </>
    );
  };
}

export default ActivityDetails;
import React from 'react';
import ActivitiesView from "./ActivitiesView";
import { FilteredActivities } from '../backend/Activities';


class KanbanColumn extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  getActivities = () => {

    // this.props.activities.

    let filters = this.props.filters ? {...this.props.filters} : {};
    let parent = this.props.parent;

    if (parent)
      filters.parent = parent.uuid;
    if (this.props.state !== undefined)
      filters.state = this.props.state;
    if (this.props.showArchived !== undefined)
      filters.show_archived = this.props.showArchived;
    // if (this.props.hideCancelled !== undefined)
    //   filters.hide_cancelled = this.props.hideCancelled;
    // if (this.props.hideCompleted !== undefined)
    //   filters.hide_completed = this.props.hideCompleted;

    return new FilteredActivities(filters);
  }

  render = () => {
    return (
      <div className="kanban-column">
        <div className="inner">
          <h6 className="title">{this.props.title}</h6>
          <ActivitiesView 
            activities={this.getActivities()}
            hideSubactivities={true}
            hidePrivate={!this.props.showPrivate}
            hideNonPrioritary={!this.props.showNonPrioritary}
            hideIcon={true}
            hideJournal={true}
            detailsBelow={true}
          />
          <div className="add" href="/"><i className="bi bi-plus"></i> Aggiungi</div>
        </div>
      </div>
    )
  }
}


export default KanbanColumn;

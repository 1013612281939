import React, { Component } from "react";
import LoginPage from "./LoginPage";
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import PmApi from '../backend/PmApi';
import PmUser from '../backend/PmUser';
import MainPage from './MainPage';
//import AppContext from './AppContext';

import 'react-contexify/ReactContexify.css';


// The global App singleton
export var globalApp = null;


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginChecked: false,
      loggedIn: null,
      pmUser: null,
    }

    globalApp = this;
  }

  // Sets the current (PM) user
  setLoggedPmUser = (pmUser) => {
    this.setState({
      loggedIn: pmUser ? true : false,
      pmUser: pmUser,   // [PmUser | null]
    })
  }

  handleLoggedIn = (data) => {
    window.location = '/';
  }

  // Check if a user is logged in and update state accordingly
  checkLogin = () => {
    const api = new PmApi();
    const _this = this;
    return api
      .get('profile')
      .then((res) => {
        // Logged in
        const pmUser = PmUser.updateLocal(res.data.uuid, res.data)
        this.setLoggedPmUser(pmUser);
      })
      .catch((err) => {
        if ((err.response.status === 401) || (err.response.status === 403)) {
          // Not logged in
          _this.setLoggedPmUser(null);
        }
        else
          // Leave unchanged in case of error
          console.error(err);
      });
  }

  componentDidMount = async () => {
    await this.checkLogin();
    this.setState({loginChecked: true});
  }

  logoutUser = async () => {
    const _this = this;
    const api = new PmApi();
    api
      .post("logout")
      .then((res) => _this.setLoggedPmUser(null))
      .catch((err) => console.error(err));
  }

  render = () =>
    <>
      {this.state.loginChecked && (
        <>
          {((this.state.loggedIn === true) && (
            <HashRouter>
              <Routes>
                <Route exact path="/main" element={<MainPage />} />
                <Route path="*" element={<Navigate to='/main'/>} />
              </Routes>
            </HashRouter>
          )) || (
            <HashRouter>
              <Routes>
                <Route exact path="/login" element={<LoginPage onLoggedIn={this.handleLoggedIn}/>} />
                <Route path="*" element={<Navigate to='/login'/>} />
              </Routes>
            </HashRouter>
          )}
        </>
      )}
    </>
}

export default App;
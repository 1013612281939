import React, { Component } from "react";
import Activity from '../backend/Activity';

/**
 * Props:
 *    activity:       The Activity
 */
export class ActivityIcon extends React.Component {

  setActivityState = async (state) => {
    await this.props.activity.setState(state);
  }

  // Called when the state is clicked.
  // Rotate the state and update it
  handleStateClick = (event) => {
    let newState = Activity.STATE_TODO;
    switch (this.props.activity.data.state) {
      case Activity.STATE_TODO:
        newState = Activity.STATE_ONGOING;
        break;
      case Activity.STATE_ONGOING:
        newState = Activity.STATE_COMPLETED;
        break;
      case Activity.STATE_COMPLETED:
        newState = Activity.STATE_ONHOLD;
        break;
      case Activity.STATE_ONHOLD:
        newState = Activity.STATE_CANCELLED;
        break;
      default:
      case Activity.STATE_CANCELLED:
        newState = Activity.STATE_TODO;
        break;
    }
    this.setActivityState(newState);
  }

  render = () => {
    let state = this.props.activity.getTypeStateIcon();

    if (state)
      return (
        <span 
            className="activity-icon"
            title={this.props.activity.data.state_display}
            onClick={this.handleStateClick}
          >
          {state}
        </span>

      );
  }
}


export default ActivityIcon;
import React, { Component } from "react";
import settings from "../settings/settings.json";

/**
 * Base page structure.
 */
class Page extends Component {

  render = () => {
    const debugClass = settings.PRODUCTION ? '' : 'navbar-dev';

    return (
      <>
        <header className="head">
          <nav className={`navbar navbar-expand-lg navbar-dark bg-dark ${debugClass}`}
          aria-label="Main navbar">
            <div className="container-fluid">
              <a className="navbar-brand" href="/"><img width="32" height="32" src={process.env.PUBLIC_URL + '/logo192.png'}></img></a>

              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainMenu" aria-controls="navbarsExample07XL" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
    
              <div className="collapse navbar-collapse">
                {this.props.mainMenu}
              </div>
              {this.props.customTopBar}
              {this.props.searchBox}
              {this.props.userMenu}    
            </div>
          </nav>          
        </header>
        {this.props.main}
      </>
    );
  }
}

export default Page;
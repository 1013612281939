import axios from "axios";
import settings from "../settings/settings.json";
// import { merge } from 'lodash';


export default class ObjLocalStorage {

  static set(key, obj) {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  static get(key) {
    let obj = {};
    try {
      obj = JSON.parse(localStorage.getItem(key));
    } catch (e) {
    }

    if (!obj)
      return {};
    else
      return obj;
  }
};

import React, { Component } from "react";

/**
 * Props:
 *  isOpen
 *  has_subactivities:  Activity data
 *  activityView:       The parent ActivityView component.
 */
class ActivityOpener extends Component {
  render = () => {
    return (
      <>
      { this.props.has_subactivities && (
      <div 
        className="opener" 
        onClick={this.props.activityView.toggleDetails}
//        onContextMenu={this.props.activityView.handleShowContextMenu}
      >
        <i className={`bi bi-caret-right-fill${this.props.isOpen ? " open" : ""}`}></i>
      </div>
      )}
      </>
    );
  }
}


export default ActivityOpener;
import React from "react";
import Activity from '../backend/Activity';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";


/**
 * Props:
 *  compact
 *  activity
 */
export class ActivityEstimatedDuration extends React.Component {
  constructor(props) {
    super(props);

    this.estimatedDurationModalRef = React.createRef();
  }

  handleClick = (event) => {
    if (this.estimatedDurationModalRef) {
      const modal = this.estimatedDurationModalRef.current;
      if (modal)
        modal.open(event);
    }
  }

  render = () => {
    const data = this.props.activity.data;

    return data.estimated_duration && (
        <span className="estdur">
          <span
              title="Edit estimated duration"
              className="estdurcal" 
              onClick={this.handleClick}>
            <i className="bi bi-clock"></i>
          { !this.props.compact && (<>
            &nbsp;{data.estimated_duration} {
              (data.estimated_duration_unit === Activity.UNIT_MINUTES) && (<>'</>)
              || (data.estimated_duration_unit === Activity.UNIT_HOURS) && (<>h</>)
              || (data.estimated_duration_unit === Activity.UNIT_DAYS) && (<>d</>)
            }
          </>)}
            <ActivityEstimatedDurationModal 
              ref={this.estimatedDurationModalRef}
              activity={this.props.activity} 
            />
          </span>
        </span>
      );
  }
}


/**
 * Props:
 *  compact
 *  activity
 *  activityView
 */
export class ActivityEstimatedDurationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { 
        estimated_duration: this.props.activity.data.estimated_duration,
        estimated_duration_unit: this.props.activity.data.estimated_duration_unit,
       }, 
      modalOpen: false
    }
  }

  close = () => {
    this.setState({ modalOpen: false });
  };

  open = (event) => {
    event.stopPropagation();
    this.setState({ modalOpen: true });
  };

  handleChange = (event) => {
    let { name, value } = event.target;

    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }

    const data = { ...this.state.data, [name]: value };
    this.setState({ data });
  };

  setActivityEstimatedDuration = async (estimated_duration, unit) => {
    const res = await this.props.activity.setEstimatedDuration(estimated_duration, unit);
  }

  setEstimatedDuration = (e) => {
    this.close();
    this.setActivityEstimatedDuration(this.state.data.estimated_duration, this.state.data.estimated_duration_unit);
  }

  removeEstimatedDuration = (e) => {
    this.close();
    this.setActivityEstimatedDuration(null);
  }      

  render = () => {
    const data = this.props.activity.data;

    return (
      <>
        <Modal isOpen={this.state.modalOpen} toggle={this.close} backdrop="static" keyboard={false} size="sm">
          <ModalHeader toggle={this.close}>{ data.estimated_duration ? "Edit" : "Set" } estimated duration</ModalHeader>
          <ModalBody>
            <Form>

              <Row>
                <Col>
                  <FormGroup>
                    <Label for="estimated_duration">Est. duration</Label>
                    <Input
                      type="text"
                      name="estimated_duration"
                      id="estimated_duration"
                      value={this.state.data.estimated_duration ? this.state.data.estimated_duration : ''}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="estimated_duration">Unit</Label>
                    <Input
                      type="select"
                      name="estimated_duration_unit"
                      id="estimated_duration_unit"
                      value={this.state.data.estimated_duration_unit ? this.state.data.estimated_duration_unit : ''}
                      onChange={this.handleChange}
                    >
                      <option value={Activity.UNIT_MINUTES}>minutes</option>
                      <option value={Activity.UNIT_DAYS}>days</option>
                      <option value={Activity.UNIT_HOURS}>hours</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

            </Form>
          </ModalBody>
          <ModalFooter>
            { data.estimated_duration && (
            <Button
              color="danger"
              onClick={this.removeEstimatedDuration}
            >
              Remove
            </Button>
            )}
            <Button
                color="secondary"
                onClick={this.close}
              >
                Cancel
            </Button>
            <Button
                color="primary"
                onClick={this.setEstimatedDuration}
              >
              Apply
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
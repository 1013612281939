import React from "react";
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap-icons/font/bootstrap-icons.css';

import 'react-modern-drawer/dist/index.css';

import './index.scss';
import App from './components/App';
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById("root"));

// NOTA: <React.StrictMode> commentato perché causa doppie chiamate a componentDidMount()
//  Vedere qui: https://stackoverflow.com/a/72072443

// StrictMode renders components twice (on dev but not production) in order to detect 
// any problems with your code and warn you about them (which can be quite useful).

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

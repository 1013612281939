import React from "react";
import Activity from '../backend/Activity';
import { MainPanesContext } from './MainPanes';



/**
 * Props:
 *    activity
 *    show
 */
class ActivityMainPaneOpener extends React.Component {
  static contextType = MainPanesContext;

  handleClick = () => {
    let mainPanes = this.context;
    mainPanes.addActivityPane(this.props.activity);
  }

  render = () => {
    return (
      <span 
        onClick={this.handleClick} 
        className={this.props.show ? "" : "hide"}
      >
        <i className="bi bi-box-arrow-up-right"></i>
      </span>
    );
  }
}

export default ActivityMainPaneOpener;
import React, { Component } from "react";
import Activity from '../backend/Activity';
import ActivityView from './ActivityView';


/**
 * View for a list of activities.
 *
 *  Props:
 *    activities
 * 
 *    hidePrivate
 *    hideNonPrioritary
 *    hideCancelled
 *    hideCompleted
 *    showPositionBadge
 *    showBreadcrumbs
 *    hideJournal
 *    level
 *    view                      "grid", "treeview", "kanban"
 * 
 *    onCreated:                callback; when a new activity has been created in this list
 *    onCreationCancelled:      callback; when creation of the new activity has been cancelled 
 *    onDeleted:                callback; when an activity of this list has been deleted
 */
class ActivitiesView extends Component {
  constructor(props) {
    super(props);

    this.activities = null;
    this.state = {
      loading: true,
      uuids: [],
    };
  }

  // Called when component mounts
  componentDidMount = () => {
    this.refresh();
  }

  // Called when component updates
  componentDidUpdate = async (prevProps) => {
    if (this.props.activities !== prevProps.activities) {
     this.refresh();
   }
 }  

  refresh = async () => {
    // console.log("ActivitiesView.refresh()");
    this.activities = this.props.activities;

    await this.activities.refresh();
    this.setState({ 
      loading: false,
      uuids: this.activities.getUuids()
    });
  }

  // Create a new activity as first activity
  createNewAtStart = async () => {
    console.debug("ActivitiesView.createNewAtStart()");
    await this.activities.refresh();
    await this.createNewAfter(null);
  }

  // Create a new activity as last activity
  createNewAtEnd = async () => {
    console.debug("ActivitiesView.createNewAtEnd()");
    await this.activities.refresh();
    const lastActivity = this.activities.getLast();
    await this.createNewAfter(lastActivity);
  }

  // Create a new activity after passed activity, with given title.
  // Copy activity type and state from passed activity.
  createNewAfter = async (activity, title) => {
    const newActivity = await this.activities.createNewAfter(activity, title);
    this.refresh();
    if (this.props.onCreated)
      this.props.onCreated(newActivity);
  }

  // Prop callback
  // Called when an activity in the list has been deleted
  // uuid: ID of the deleted activity
  handleDeleted = async (uuid) => {
    console.debug('ActivitiesView.handleDeleted()')
    await this.refresh();
    if (this.props.onDeleted)
      this.props.onDeleted(uuid);
  }

  // Set the activity type of all activities in this list to `type`
  setActivitiesType = async (type) => {
    const res = await this.activities.setAllType(type);
  }

  // Prop callback
  // Called when an activity has been dropped in a dropzone within this element
  handleDropped = async (event, parent, after) => {
    event.preventDefault();
    event.stopPropagation();

    console.log(window.activityDragging);
    const activity = window.activityDragging.activity;
    console.debug('ActivitiesView.handleDropped(): activity: ' +  activity.uuid + 
    '; parent: ' + (parent ? parent.uuid : "null") + ', after: ' + (after ? after.uuid : "null") + ')');

    await activity.moveTo(parent, after);
    await window.activityDragging.originList.movedOut(activity);
    await this.refresh();
  }

  // Prop callback
  // Called when an an activity has been moved from this list
  movedOut = async (activity) => {
    await this.refresh();
  }

  // Close all the activities
  closeAll = () => {
    this.state.uuids.map((uuid) => {
      const activity = Activity.getByUuid(uuid);
      if (activity)
        activity.setIsOpen(false);
        activity.setIsJournalOpen(false);
    });
  };

  // Open all the sub-activities
  openAll = () => {
    this.state.uuids.map((uuid) => {
      const activity = Activity.getByUuid(uuid);
      if (activity)
        activity.setIsOpen(true);
    });
  };

  render = () => {
    // console.debug("ActivitiesView.render()");

    return (
      <div className="activities">
        {this.state.uuids.map((uuid) => {
          const activity = Activity.getByUuid(uuid);
          if (activity)
            return (
              <ActivityView
                key={uuid}
                view={this.props.view}
                activity={activity}
                isSortable={this.props.activities.isSortable()}
                hidePrivate={this.props.hidePrivate}
                hideNonPrioritary={this.props.hideNonPrioritary}
                hideCancelled={this.props.hideCancelled}
                hideCompleted={this.props.hideCompleted}
                showArchived={this.props.showArchived}
                showPositionBadge={this.props.showPositionBadge}
                showBreadcrumbs={this.props.showBreadcrumbs}
                list={this}
                level={this.props.level ? this.props.level : 1}
                dragging={this.props.dragging}
                hideSubactivities={this.props.hideSubactivities}
                hideIcon={this.props.hideIcon}
                hideJournal={this.props.hideJournal}
                detailsBelow={this.props.detailsBelow}
                onDeleted={this.handleDeleted}
                onMovedOut={this.handleMovedOut}
                onEnterKeyPressed={this.handleEnterKeyPressed}
                createNewAfter={this.createNewAfter}
              />
            );
        })}

      </div>
    )
  };
}

export default ActivitiesView;
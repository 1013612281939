import React from "react";
import ActivityLabel from "../backend/ActivityLabel";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";


/**
 * Props:
 *  label
 *  compact
 * 
 *  onClick     Click on the label, not the "X"
 */
class ActivityLabelView extends React.Component {
  constructor(props) {
    super(props);
    this.labelsModalRef = React.createRef();
  }

  render = () => {
    if (!this.props.label)
      return null;
    const data = this.props.label.data;
    return (
      <div 
        className={`label ${this.props.size ? this.props.size : ""} ${this.props.compact ? "mini" : ""}`} 
        title={data.name} 
        style={{backgroundColor: data.color}} 
        onClick={this.props.onClick}
      >
        <div className="label-name">{data.name}</div>
      </div>
    )
  };
}


/**
 * Props:
 *  activity
 */
export class ActivityLabelsView extends React.Component {
  constructor(props) {
    super(props);
    this.labelsModalRef = React.createRef();
    this.state = {
      compact: true,
    };
  }

  // Action
  toggleCompact = (evt) => {
    evt.stopPropagation();
    this.setState({ compact: !this.state.compact });
  }

  render = () => {
    return (
      <>
      { (this.props.activity.data.uuids_of_labels.length > 0) && (
        <div 
          className="labels"
          onClick={this.toggleCompact}
        >
        {this.props.activity.data.uuids_of_labels.map(uuid => {
          const label = ActivityLabel.getByUuid(uuid);
          return (
            <ActivityLabelView 
              key={uuid} 
              compact={this.state.compact} 
              label={label}
              size="sm"
            />
          );
        })}
        </div>
      )}
      </>
    )
  };
}


/**
 * Props:
 *  activity
 *  activityView
 */
export class ActivityLabelsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uuids_of_labels: [], 
      modalOpened: false
    }

    this.labels = null;
  }

  componentDidMount = async () => {
    this.labels = await ActivityLabel.getAll();
    this.setState({
      uuids_of_labels: this.props.activity.data.uuids_of_labels || [], 
    });
  }

  close = () => {
    this.setState({ modalOpened: false });
  };

  open = (event) => {
    event.stopPropagation();
    this.setState({ modalOpened: true });
  };

  setLabel = (uuid, present) => {
    let uuids_of_labels = [...this.state.uuids_of_labels];
    if (present) {
      // Add to array
      uuids_of_labels.push(uuid);
    }
    else {
      // Remove from array
      var index = uuids_of_labels.indexOf(uuid);
      if (index !== -1) {
        uuids_of_labels.splice(index, 1);
      }      
    }

    this.setState({ uuids_of_labels: uuids_of_labels });
  };

  setActivityLabels = async (uuids_of_labels) => {
    await this.props.activity.setLabels(uuids_of_labels);
  }

  setLabels = (e) => {
    this.close();
    this.setActivityLabels(this.state.uuids_of_labels);
  }

  removeLabels = (e) => {
    this.close();
    this.setActivityLabels([]);
  }      

  render = () => {
    return (
      <>
        {this.state.modalOpened && (
        <Modal isOpen={true} toggle={this.close} backdrop="static" keyboard={false} size="sm">
          <ModalHeader toggle={this.close}>
            Labels
          </ModalHeader>
          <ModalBody>
          {this.labels && (
            <ul className="label-selection">
              { [...this.labels].map(([uuid, label]) => { 
                const hasLabel = this.state.uuids_of_labels.includes(uuid); 
                return (
                  <li key={uuid}>
                    <Input
                      type="switch"
                      checked={hasLabel}
                      onChange={() => this.setLabel(uuid, !hasLabel)}
                    />
                    <ActivityLabelView 
                      label={label} 
                      onClick={() => this.setLabel(uuid, !hasLabel)} 
                    />
                  </li>
                )}
              )}
            </ul>
          )}
          </ModalBody>
          <ModalFooter>
            { this.props.activity.data.uuids_of_labels.size && (
            <Button
              color="danger"
              onClick={this.removeLabels}
            >
              Remove all
            </Button>
            )}
            <Button
                color="secondary"
                onClick={this.close}
              >
                Cancel
            </Button>
            <Button
                color="primary"
                onClick={this.setLabels}
              >
              Apply
            </Button>
          </ModalFooter>
        </Modal>
        )}
      </>
    );
  }
}


export default ActivityLabelsView;
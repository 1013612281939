import React from "react";
import InlineEditor from "./InlineEditor";
import PmApi from '../backend/PmApi';
import {
  Button,
} from "reactstrap";


/**
 * Props:
 *    activity:       The Activity
 *    activityView:   If this <ActivityHead /> is contained within an <ActivityView />
 *    enterSplitsActivity
 */
class ActivityHead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.activity.data.title,
      dragOver: false,          // false: not over; "head": drag over head; "left": drag over left
      count: 0,
    };

    // Counters needed to tackle with drag enter/leave on child elements
    // https://stackoverflow.com/a/21002544
    this.dragHeadCounter = 0;

    this.titleEditorRef = React.createRef();
  }

  // Called when component is created
  componentDidMount = () => {
    if (this.props.activity.isJustCreated()) {
      this.titleEditorRef.current.focus();
    }

    // // Register this ActivityView for notifications by the BackendObject (Activity)
    // this.props.activity.registerListener(this);
  }

  // Invoked immediately before this component is unmounted and destroyed
  componentWillUnmount = () => {
    // // Unregister this ActivityView from notifications by the BackendObject (Activity)
    // this.props.activity.unregisterListener(this);
  }

  setActivityTitle = async (title) => {
    await this.props.activity.setTitle(title);
  }

  // Prop callback.
  // Called when the title is changed in the InlineEditor component
  handleTitleChanged = async (title) => {
    await this.setActivityTitle(title);
  }

  // Prop callback.
  // Called when the ENTER key is pressed in the activity title; which means to save (create) the
  // current activity & start creating a new activity just below that.
  handleEnterKeyPressedInTitle = async (text1, text2) => {
    // console.debug("AcivityView.handleEnterKeyPressedInTitle()");
    if (this.props.activityView && this.props.enterSplitsActivity) {
      await this.setActivityTitle(text1);
      this.props.activityView.createNewAfterThis(text2);
    }
  }

  handleDragEnterHead = (event) => {
    if (this.props.dragging || this.state.dragging)
      return;
    event.preventDefault();
    event.stopPropagation();
    this.dragHeadCounter++;
    this.setState({ dragOver: "head" });
  }
  
  handleDragLeaveHead = (event) => {
    if (this.props.dragging || this.state.dragging)
      return;
    event.preventDefault();
    event.stopPropagation();
    if (--this.dragHeadCounter === 0)
      this.setState({ dragOver: false });
  }
  
  handleDragOverHead = (event) => {
    if (this.props.dragging || this.state.dragging)
      return;
    event.preventDefault();
  }

  handleDropHead = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.dragging || this.state.dragging)
      return;
    console.log("ActivityView.handleDropHead()")
    this.setState({ dragOver: false });

    // Move dragged activity as first child activity 
    const activity = window.activityDragging.activity;
    await activity.moveTo(this.props.activity, null);
    window.activityDragging.originList.movedOut(activity);
    if (this.props.list)
      this.props.list.refresh();
  }

  render = () => {
    return (
      <div 
        className={`title ${this.state.dragOver === "head" ? "drop" : ""}`}
        onDragEnter={this.handleDragEnterHead}
        onDragLeave={this.handleDragLeaveHead}
        onDragOver={this.handleDragOverHead}
        onDrop={this.handleDropHead}
      >
        <InlineEditor 
          placeholder="Enter title..."
          ref={this.titleEditorRef}
          value={this.state.title}
          onChanged={this.handleTitleChanged}
          onEnterKeyPressed={this.handleEnterKeyPressedInTitle}
        />

      {(this.state.dragOver === "head") && (
        <div className="drop-line"></div>  
      )} 

      </div>
    );    
  }
}

export default ActivityHead;
import React from "react";
import ActivityListPane from "./ActivityListPane";
import ActivityGridPane from "./ActivityGridPane";
import ActivityKanbanPane from "./ActivityKanbanPane";
import ActivityTablePane from "./ActivityTablePane";
import {
  Button,
  ButtonGroup,
} from "reactstrap";


/**
 * Props:
 *      activities
 * 
 *      preTools
 *      showPositionBadge
 *      showBreadcrumbs
 *      showPrivate
 *      showNonPrioritary
 *      showCancelled
 *      showCompleted
 *      showArchived
 *      listOnly
 */
class ActivitySinglePane extends React.Component {
  constructor(props) {
    super(props);

    // console.log("ActivitySinglePane.constructor()");
    // console.log(this.props.activities);
    // console.log(this.props.activities.getViewMode);

    this.state = {
      singleViewMode: this.props.activities.getViewMode(),  // "list" | "kanban" | "gantt" | "grid"
    };
  }

  // Called when component is created
  componentDidMount = () => {
    //   this.setSingleViewMode('grid');
  }

  setSingleViewMode = (m) => {
    this.setState({ singleViewMode: m });
    this.props.activities.setViewMode(m);
  };

  render = () => {
    const switchButtons = 
      <>
        <ButtonGroup className="me-2" size="sm">
          <Button
            color="primary"
            onClick={() => this.setSingleViewMode('list')}
            active={this.state.singleViewMode === 'list'}
            title="List">
            <i className="bi bi-list-nested"></i>
          </Button>
          <Button
            color="primary"
            onClick={() => this.setSingleViewMode('grid')}
            active={this.state.singleViewMode === 'grid'}
            title="Grid">
            <i className="bi bi-grid-3x3-gap"></i>
          </Button>
          <Button
            color="primary"
            onClick={() => this.setSingleViewMode('kanban')}
            active={this.state.singleViewMode === 'kanban'}
            title="Kanban">
            <i className="bi bi-kanban"></i>
          </Button>
          <Button
            color="primary"
            onClick={() => this.setSingleViewMode('table')}
            active={this.state.singleViewMode === 'table'}
            title="Table">
            <i className="bi bi-table"></i>
          </Button>
          <Button
            color="primary"
            onClick={() => this.setSingleViewMode('calendar')}
            active={this.state.singleViewMode === 'calendar'}
            disabled={true}
            title="Table">
            <i className="bi bi-calendar3"></i>
          </Button>
          <Button
            color="primary"
            onClick={() => this.setSingleViewMode('gantt')}
            active={this.state.singleViewMode === 'gantt'}
            disabled={true}
            title="Gantt">
            <i className="bi bi-calendar2-range"></i>
          </Button>
        </ButtonGroup>
      </>;

    return (
      <>
        {((this.state.singleViewMode === 'list') && (
          <ActivityListPane
            num={0}
            activities={this.props.activities}
            otherTools={this.props.listOnly ? null : switchButtons}
            preTools={this.props.preTools}
            showPositionBadge={this.props.showPositionBadge}
            showBreadcrumbs={this.props.showBreadcrumbs}
            showPrivate={this.props.showPrivate}
            showNonPrioritary={this.props.showNonPrioritary}
            showCancelled={this.props.showCancelled}
            showCompleted={this.props.showCompleted}
            showArchived={this.props.showArchived}
          />
        )) || ((this.state.singleViewMode === 'grid') && (
          <ActivityGridPane
            activities={this.props.activities}
            otherTools={switchButtons}
            preTools={this.props.preTools}
            showPrivate={this.props.showPrivate}
            showNonPrioritary={this.props.showNonPrioritary}
            showBreadcrumbs={this.props.showBreadcrumbs}
            showCancelled={this.props.showCancelled}
            showCompleted={this.props.showCompleted}
            showArchived={this.props.showArchived}
          />
        )) || ((this.state.singleViewMode === 'kanban') && (
          <ActivityKanbanPane
            activities={this.props.activities}
            otherTools={switchButtons}
            preTools={this.props.preTools}
            showPrivate={this.props.showPrivate}
            showNonPrioritary={this.props.showNonPrioritary}
            showBreadcrumbs={this.props.showBreadcrumbs}
            showCancelled={this.props.showCancelled}
            showCompleted={this.props.showCompleted}
            showArchived={this.props.showArchived}
          />
        )) || ((this.state.singleViewMode === 'gantt' || this.state.singleViewMode === 'table') && (
          <ActivityTablePane
            activities={this.props.activities}
            otherTools={switchButtons}
            preTools={this.props.preTools}
            showPrivate={this.props.showPrivate}
            showNonPrioritary={this.props.showNonPrioritary}
            showBreadcrumbs={this.props.showBreadcrumbs}
            showCancelled={this.props.showCancelled}
            showCompleted={this.props.showCompleted}
            showArchived={this.props.showArchived}
          />
        ))}
      </>
    );
  }
}


export default ActivitySinglePane;
import React from "react";
import { ActivityJournalEntries } from '../components/ActivityJournalEntries';
import ActivityHead from './ActivityHead';
import { ActivityIcon } from './ActivityIcon';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";


/**
 * Props:
 *    activity
 *    showTools
 */
export class ActivityJournalIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editModalOpen: false
    }

    this.journalModalRef = React.createRef();
  }

  // Action
  // Toggle visibility of the activity journal
  handleJournalClick = async (event) => {
    event.stopPropagation();
//    await this.activity.setIsJournalOpen(!this.state.journalOpen);

    if (this.journalModalRef) {
      const modal = this.journalModalRef.current;
      if (modal)
        modal.open(event);
    }
  }


  render() {
    const data = this.props.activity.data;
    const show = this.props.show || data.has_journal_entries; 

    return (
      <>
        <span
          className={`journal-icon ${show ? '' : 'hide' }`}
          title="Activity journal"
          onClick={this.handleJournalClick}
        >
          <i className="bi bi-journal"></i>
          <ActivityJournalModal
            ref={this.journalModalRef}
            activity={this.props.activity}
          />
        </span>
      </>
    );
  }
}


/**
 * Props:
 *  activity
 */
export class ActivityJournalModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
    }
    this.journalEntriesRef = React.createRef();
  }

  close = () => {
    this.setState({ 
      modalOpened: false,
    });
  };

  open = async (event) => {
    event.stopPropagation();
    this.setState({ 
      modalOpened: true,
    });
  };

  handleAdd = (event) => {
    this.journalEntriesRef.current.handleAdd(event);
  };

  render = () => {
    const data = this.props.activity.data;

    return (
      <>
        {this.state.modalOpened && (
        <Modal isOpen={true} toggle={this.close} backdrop="static" keyboard={false} size="lg">
          <ModalHeader toggle={this.close}>
            <span className="me-2">
              <ActivityIcon
                activity={this.props.activity}
              />
            </span>
            {/* <ActivityHead activity={this.props.activity} /> */}
            { this.props.activity.data.title }
          </ModalHeader>
          <ModalBody>
            <ActivityJournalEntries
              ref={this.journalEntriesRef}
              activity={this.props.activity} 
            />
          </ModalBody>
          <ModalFooter>
            <Button
                color="primary"
                onClick={this.handleAdd}
              >
              New
            </Button>
            <Button
              color="secondary"
              onClick={this.close}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        )}
      </>
    );
  }
}
import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Form,
  } from "reactstrap";
import Page from "./Page";
import PmApi from '../backend/PmApi';
import {FormField, FormSpinner, FormNonFieldErrors} from './forms';


/**
 * Page for user login.
 */
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      login: PmApi.makeFormData({
        username: "", 
        password: "",
      }),
    }
  }

  handleChange = (e) => {
    let { name, value } = e.target;

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }

    const data = { ...this.state.data, [name]: value };
    this.setState({ data: data });
  };

  login = (e) => {
    const api = new PmApi();
    api.formCall("postNoToken", this, "login", "login", (res) => {
      this.props.onLoggedIn(res.data);
    });
  }

  render = () => {
    const data = this.state.data;
    return <Page 
      main={
        <main className="main main-defaultbg d-flex justify-content-center align-items-center">
          <Form className="login-form" method="post">
            <Card className="shadow">
              <CardHeader>
                <strong>Login to PM</strong>
                <div className="float-end">
                  <FormSpinner
                    parent={this}
                    groupName="login"
                  />
                </div>
              </CardHeader>
              <CardBody>
                <img className="logo" src="/logo192.png" alt="" width="80" height="80" />
                <hr />
                <p>Please log in:</p>
                <FormNonFieldErrors
                  parent={this}
                  groupName="login"
                />

                <FormField
                  type="text"
                  label="Username"
                  name="username"
                  placeholder="Enter username"
                  parent={this}
                  groupName="login"
                />
                <FormField
                  type="passwordEye"
                  label="Password"
                  name="password"
                  placeholder="Enter password"
                  parent={this}
                  groupName="login"
                />

                <p><small><a href="/">Forgot password?</a></small></p>
              </CardBody>  
              <CardFooter className="text-muted text-center">
                <Button 
                  color="primary" 
                  disabled={this.state.login.submitting}
                  onClick={this.login}>
                    Login
                </Button>
              </CardFooter>
            </Card>
          </Form>
        </main>
      }
    />
  }  
}

export default LoginPage;

import React from "react";
import ActivitiesView from './ActivitiesView';
import ActivityPane from './ActivityPane';


/**
 * Props:
 *      activities (from ActivityPane)
 * 
 *      showArchived
 *      showPrivate
 *      showNonPrioritary
 *      showCancelled
 *      showCompleted
 * 
 *      showPositionBadge
 *      showBreadcrumbs 
 */
class ActivityTablePane extends ActivityPane {

  // Close all the activities
  closeAll = () => {
    this.activitiesViewRef.current.closeAll();
  };

  // Open all the activities
  openAll = () => {
    this.activitiesViewRef.current.openAll();
  };

  renderMain = () => {
    return (
      <div className="main main-scroll">
        <div className="container">
          <div className="bg" />
          <div className="activity-table">
            <ActivitiesView
              ref={this.activitiesViewRef}
              activities={this.props.activities}
              hideJournal={false}
              hideIcon={false}
              hidePrivate={!this.props.showPrivate}
              hideNonPrioritary={!this.props.showNonPrioritary}
              hideCancelled={!this.props.showCancelled}
              hideCompleted={!this.props.showCompleted}
              showArchived={this.props.showArchived}
            />
          </div>
        </div>
      </div>
    );
  }
}


export default ActivityTablePane;
import axios from "axios";
import settings from "../settings/settings.json";
// import { merge } from 'lodash';


// Wrapper class around backend API
// Implement passing the CSRF token.
class PmApi {
  static ENCODING_JSON = 1;
  static ENCODING_FORMDATA = 2;
  static ENCODING_XWWWFORM = 3;

  constructor() {
    this.endpoint = settings.BACKEND.ENDPOINT;
    this.setJsonEncoding();
  }

  setEncoding(encoding) {
    this.encoding = encoding;
    return this;
  }

  setJsonEncoding() {
    this.encoding = PmApi.ENCODING_JSON;
    return this;
  }

  setFormDataEncoding() {
    this.encoding = PmApi.ENCODING_FORMDATA;
    return this;
  }

  setXWwwFormEncoding() {
    this.encoding = PmApi.ENCODING_XWWWFORM;
    return this;
  }

  getConfig() {
    const csrftoken = this._getCookie('csrftoken');
    let config = {
      headers: {
        'X-CSRFToken': csrftoken,
      }
    }
    if (this.encoding === PmApi.ENCODING_JSON) {
      config.headers['Content-Type'] = 'application/json';
    }
    else if (this.encoding === PmApi.ENCODING_FORMDATA) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (this.encoding === PmApi.ENCODING_XWWWFORM) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    return config;
  }

  getCallData(data) {
    switch (this.encoding) {
      case PmApi.ENCODING_JSON:
        return data;

      case PmApi.ENCODING_FORMDATA:
      case PmApi.ENCODING_XWWWFORM:
        let formData = new FormData();
        for (const [key, value] of Object.entries(data))
          formData.append(key, value);

        return formData;

      default:
        return {};
    }
  }

  get(path, data) {
    let url = this.endpoint + path;

    if (data) {
      let url2 = new URL(url);
      Object.keys(data).forEach((item) => url2.searchParams.append(item, data[item]));
      url = url2.href;
    }

    return axios
      .get(url);
  }

  postNoToken(path, data) {
    const url = this.endpoint + path;
    const callData = this.getCallData(data);
    return axios
      .post(url, callData);
  }

  post(path, data) {
    const url = this.endpoint + path;
    const config = this.getConfig();
    const callData = this.getCallData(data);
    return axios
      .post(url, callData, config);
  }

  put(path, data) {
    const url = this.endpoint + path;
    const config = this.getConfig();
    const callData = this.getCallData(data);
    return axios
      .put(url, callData, config);
  }

  patch(path, data) {
    const url = this.endpoint + path;
    const config = this.getConfig();
    const callData = this.getCallData(data);
    return axios
      .patch(url, callData, config);
  }

  delete(path) {
    const url = this.endpoint + path;
    const config = this.getConfig();
    return axios.delete(url, config);
  }

  _getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  // Prepare state data to be used for `formCall`
  static makeFormData = (data) => {
    return {
      data: data, 
      submitting: false,
      errors: {},
    }
  }

  // Perform a form call
  formCall = async (method, parent, groupName, endpoint, success) => {
    const data = parent.state[groupName].data;
    const g = {...parent.state[groupName], submitting: true, errors: {} };
    parent.setState({ [groupName]: g });
    let _this = this;
    return _this[method](endpoint, data)
      .then((res) => {
        const g = {...parent.state[groupName], submitting: false, errors: {} };
        parent.setState({ [groupName]: g });
        if (success) 
          success(res);
        return res;
      })
      .catch((err) => {
        if (err.response.status === 400) {
          console.log("data", err.response.data);
          const g = { ...parent.state[groupName], submitting: false, errors: err.response.data}
          parent.setState({ [groupName]: g });
        }
        else {
          console.log(err); 
          const g = { ...parent.state[groupName], submitting: false, errors: {non_field_errors: "Failed"}}
          parent.setState({ [groupName]: g });
        }
        return null;
      });
  }
}

export default PmApi;

import PmApi from '../backend/PmApi';
import BackendObject from '../backend/BackendObject';


export default class ActivityLabel extends BackendObject {
  // Maps uuid to ActivityLabel object
  static uuidToLabel = new Map();

  // Get an ActivityLabel by its uuid
  static getByUuid(uuid) {
    if (ActivityLabel.uuidToLabel.has(uuid))
      return ActivityLabel.uuidToLabel.get(uuid);
    else 
      return null;
  }

  // Save a ActivityLabel by its uuid
  static async setByUuid(uuid, label) {
    ActivityLabel.uuidToLabel.set(uuid, label);
  }

  // Create a new local copy of ActivityLabel or update the existing local copy
  static updateLocal = (uuid, data) => {
    if (ActivityLabel.uuidToLabel.has(uuid)) {
      let label = ActivityLabel.uuidToLabel.get(uuid);
      label.uuid = uuid;
      label.data = data;
      return label;
    }
    else {
      let label = new ActivityLabel(uuid, data);
      ActivityLabel.setByUuid(uuid, label);
      return label;
    }
  }

  // Update local instances of ActivityLabel
  static updateLocalMany = (data) => {
    data.map(item => ActivityLabel.updateLocal(item.uuid, item));
  }

  // Load all labels from backend
  static loadAll = async () => {
    try {
      const api = new PmApi();
      const res = await api.get("labels");
      this.updateLocalMany(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  // Return all labels
  static getAll = async () => {
    return ActivityLabel.uuidToLabel;
  }

  // Load a single label; use the cached copy if available.
  static load = async (uuid) => {

    let label = ActivityLabel.getByUuid(uuid);
    if (label)
      return label;

    const api = new PmApi();
    let res = await api.get(`labels/${uuid}`);
    // TODO: gestire i 404!
    return ActivityLabel.updateLocal(res.data.uuid, res.data);
  };

  constructor(uuid, data) {
    super();
    this.uuid = uuid;
    this.data = data;
  }

  // Refresh contents of this label
  refresh = async () => {
    console.debug("ActivityLabel.refresh()");

    this.data = {};

    const api = new PmApi();
    try {
      const res = await api.get(`labels/${this.uuid}`);
      this.data = res.data;
    } catch (err) {
      console.error(err);
    }
  }
}


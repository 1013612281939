import PmApi from '../backend/PmApi';
import Activity from '../backend/Activity';


/**
 * A generic list of activities.
 * May have a parent, in which case it is (a subset of) the children of an activity.
 */
export class Activities {
  map = (callback) => {
    throw new Error('Not implemented');
  }

  // Get array of UUIDs of subactivities
  getUuids = () => {
    throw new Error('Not implemented');
  } 

  // Get the last activity
  getLast = () => {
    throw new Error('Not implemented');
  } 

  // Get UUID of last activity in list
  getLastUuid = () => {
    throw new Error('Not implemented');
  } 

  // Remove activity #uuid from list.
  // Typically called when activity has been deleted
  remove = (uuid) =>  {
    throw new Error('Not implemented');
  }

  // Set view mode for this list
  // Default implementation does nothing, can be overridden in derived classes
  setViewMode = (m) => {
  }

  // Return the view mode for this list
  // Default implementation returns the default view mode, can be overridden in derived classes
  getViewMode = () => {
    return Activity.DEFAULT_VIEW_MODE;
  }

  isSortable = () => {
    return false;
  }

  canAddNew = () => {
    return false;
  }

  // Returns true if this list has a parent activity
  hasParent = () => {
    return false;
  }

  // Returns the parent activity, if any
  getParent = () => {
    return null;
  }

  refresh = async () => {
    throw new Error('Not implemented');
  }

  // Set type of all activities in this list
  setAllType = async (type) => {
    throw new Error('Not implemented');
  }

  createNewAfter = async (activity, title) => {
    throw new Error('Not implemented');
  }
};


/**
 * List of activities cached in a local list.
 */
export class ListedActivities extends Activities {
  constructor() {
    super();
    this.activities = [];
    this.filters = {};
  }

  map = (callback) => {
    this.activities.map(callback);
  }

  // Get array of UUIDs of subactivities
  // Possibly, filter by: state
  getUuids = () => {
    let uuids = [];
    for (let i = 0; i < this.activities.length; i++) {
      let activity = this.activities[i];
      uuids.push(activity.uuid);
    }
    return uuids;
  } 

  // Get the last activity
  getLast = () => {
    if (this.activities.length > 0)
      return this.activities.slice(-1)[0];
    else
      return null;
  } 

  // Get UUID of last activity in list
  getLastUuid = () => {
    let activity = this.getLast();
    if (activity)
      return activity.uuid;
    else
      return null;
  } 

  // Remove activity #uuid from list.
  // Typically called when activity has been deleted
  remove = (uuid) =>  {
    let index = this.activities.find(activity => activity.uuid === uuid);
    if (index !== undefined)
      this.activities.splice(index, 1);
  }
};


export class ChildActivities extends ListedActivities {
  constructor(activity) {
    super();
    this.parent = activity;
  }

  hasParent = () => {
    return true;
  }

  getParent = () => {
    return this.parent;
  }

  isSortable = () => {
    return true;
  }

  canAddNew = () => {
    return true;
  }

  // Set view mode for this list
  setViewMode = (m) => {
    this.parent.setViewMode(m);
  }

  // Return the view mode for this list
  getViewMode = () => {
    return this.parent.getViewMode();
  }

  // Update activities in the current activity list
  update = (data) => {
    this.activities = data.map((data) => {
        const activity = Activity.updateLocal(data.uuid, data);
        return activity;
    });
  }

  // Load or reload contents from backend
  refresh = async () => {
    // console.log("ChildActivities.refresh()");
    const api = new PmApi();
    try {
      const filters = { parent: this.parent.uuid }
      const res = await api.get("activities", filters);
      this.update(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  // refresh = async () => {
  //   this.activities = this.parent.getSubactivities();
  // }

  // Set type of all activities in this list
  setAllType = async (type) => {
    const api = new PmApi();
    const params = { parent: this.parent.uuid }
    const paramsStr = new URLSearchParams(params).toString();      
    let url = `activities?${paramsStr}`;
    let res = await api.patch(url, { type: type });
    this.update(res.data);
  }

    // Create a new activity after passed activity, with given title.
  // Copy activity type and state from passed activity.
  createNewAfter = async (activity, title) => {
    let newType = Activity.TYPE_CHECKBOX;
    let newState = Activity.STATE_TODO;

    if (activity) { // child from sibling
      switch (activity.data.type) {
        case Activity.TYPE_CHECKBOX:
          newType = Activity.TYPE_CHECKBOX;
          newState = Activity.STATE_TODO;
          break;
        case Activity.TYPE_PROJECT:
          newType = Activity.TYPE_PROJECT;
          newState = Activity.STATE_ONGOING;
          break;
        // case Activity.TYPE_DEFAULT:
        //   newType = Activity.TYPE_DEFAULT;
        //   newState = Activity.STATE_NEW;
        //   break;
        case Activity.TYPE_FOLDER:
          newType = Activity.TYPE_FOLDER;
          newState = Activity.STATE_TODO;
          break;
      }
    }
    else if (this.parent) { // child from parent
      switch (this.parent.data.type) {
        case Activity.TYPE_CHECKBOX:
          newType = Activity.TYPE_CHECKBOX;
          newState = Activity.STATE_ONGOING;
          break;
        case Activity.TYPE_PROJECT:
          newType = Activity.TYPE_CHECKBOX;
          newState = Activity.STATE_TODO;
          break;
        // case Activity.TYPE_DEFAULT:
        //   newType = Activity.TYPE_DEFAULT;
        //   newState = Activity.STATE_NEW;
        //   break;
        case Activity.TYPE_FOLDER:
          newType = Activity.TYPE_CHECKBOX;
          newState = Activity.STATE_TODO;
          break;
      }
    }
    const data = { 
      title: title ? title : '', 
      type: newType, 
      state: newState,
    } 
    const newActivity = await Activity.create(data, this.parent, activity);
    return newActivity;
  }
};


export class FilteredActivities extends ListedActivities {
  constructor(filters) {
    super();
    this.filters = filters;
  }

  isSortable = () => {
    return false;
  }

  canAddNew = () => {
    return false;
  }

  // Update activities in the current activity list
  update = (data) => {
    this.activities = data.map((data) => {
        const activity = Activity.updateLocal(data.uuid, data);
        return activity;
    });
  }

  // Load or reload contents from backend
  refresh = async () => {
    const api = new PmApi();
    try {
      const res = await api.get("activities", this.filters);
      this.update(res.data);
    } catch (err) {
      console.error(err);
    }
  }
};


export class OwnActivities extends FilteredActivities {
  constructor(pmUser) {
    super({ pm_user: pmUser.uuid });
  }
};


export class RootActivities extends FilteredActivities {
  constructor() {
    super({});
  }

  isSortable = () => {
    return true;
  }

  canAddNew = () => {
    return true;
  }
  
  // Create a new activity after passed activity, with given title.
  // Copy activity type and state from passed activity.
  createNewAfter = async (activity, title) => {
    const data = { 
      title: title ? title : 'New workspace', 
      type:  activity ? activity.data.type : Activity.TYPE_WORKSPACE, 
      state: Activity.STATE_TODO,
    } 
    const newActivity = await Activity.create(data, this.parent, activity);
    return newActivity;
  }  
};


export class WrappedActivities extends Activities {
  constructor(activities) {
    super();
    this.activities = activities;
    this.filters = undefined;
  }

  setFilters = (filters) => {
    this.filters = filters;
  }

  isSortable = () => {
    return false;
  }

  canAddNew = () => {
    return false;
  }

  // Load or reload contents from backend
  refresh = async () => {
    // console.log("ChildActivities.refresh()");
    const api = new PmApi();
    try {
      const filters = { parent: this.parent.uuid }
      const res = await api.get("activities", filters);
      this.update(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  // refresh = async () => {
  //   this.activities = this.parent.getSubactivities();
  // }

  // Set type of all activities in this list
  setAllType = async (type) => {
    const api = new PmApi();
    const params = { parent: this.parent.uuid }
    const paramsStr = new URLSearchParams(params).toString();      
    let url = `activities?${paramsStr}`;
    let res = await api.patch(url, { type: type });
    this.update(res.data);
  }

    // Create a new activity after passed activity, with given title.
  // Copy activity type and state from passed activity.
  createNewAfter = async (activity, title) => {
    let newType = Activity.TYPE_CHECKBOX;
    let newState = Activity.STATE_TODO;

    if (activity) { // child from sibling
      switch (activity.data.type) {
        case Activity.TYPE_CHECKBOX:
          newType = Activity.TYPE_CHECKBOX;
          newState = Activity.STATE_TODO;
          break;
        case Activity.TYPE_PROJECT:
          newType = Activity.TYPE_PROJECT;
          newState = Activity.STATE_ONGOING;
          break;
        // case Activity.TYPE_DEFAULT:
        //   newType = Activity.TYPE_DEFAULT;
        //   newState = Activity.STATE_NEW;
        //   break;
        case Activity.TYPE_FOLDER:
          newType = Activity.TYPE_FOLDER;
          newState = Activity.STATE_TODO;
          break;
      }
    }
    else if (this.parent) { // child from parent
      switch (this.parent.data.type) {
        case Activity.TYPE_CHECKBOX:
          newType = Activity.TYPE_CHECKBOX;
          newState = Activity.STATE_ONGOING;
          break;
        case Activity.TYPE_PROJECT:
          newType = Activity.TYPE_CHECKBOX;
          newState = Activity.STATE_TODO;
          break;
        // case Activity.TYPE_DEFAULT:
        //   newType = Activity.TYPE_DEFAULT;
        //   newState = Activity.STATE_NEW;
        //   break;
        case Activity.TYPE_FOLDER:
          newType = Activity.TYPE_CHECKBOX;
          newState = Activity.STATE_TODO;
          break;
      }
    }
    const data = { 
      title: title ? title : '', 
      type: newType, 
      state: newState,
    } 
    const newActivity = await Activity.create(data, this.parent, activity);
    return newActivity;
  }
};



import React, { Component } from "react";

/**
 * Displays the activity breadcrumbs.
 * 
 * Props:
 *    activity
 */
class ActivityBreadcrumbs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parent: null,
    };
  }

  // Called when component mounts
  componentDidMount = async () => {
    let parent = this.props.activity ? await this.props.activity.parentActivity() : null;
    this.setState({
      parent: parent
    });
  }

  // Called when component updates
  componentDidUpdate = async (prevProps) => {
    if (this.props.activity !== prevProps.activity) {
      let parent = this.props.activity ? await this.props.activity.parentActivity() : null;
      this.setState({
        parent: parent
      });
    }
  }  

  render = () => {
    if (this.props.activity)
      return (
          <>
            {(!this.props.inner && (
              <ol className="breadcrumb">
                <ActivityBreadcrumbs 
                  inner={true} 
                  activity={this.state.parent} 
                  onClick={this.props.onClick}
                />
              </ol>
            )) || (
              <>
                <ActivityBreadcrumbs 
                  inner={true} 
                  activity={this.state.parent} 
                  onClick={this.props.onClick}
                />
                <li 
                  className="breadcrumb-item"
                  onClick={() => this.props.onClick(this.props.activity)}
                >
                  {this.props.activity.data.title}
                </li>
              </>
            )}
          </>
        );
  }
}


export default ActivityBreadcrumbs;
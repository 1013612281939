import React from 'react';
import PmUserIcon from './PmUserIcon';


/**
 * A card for a PmUser object.
 * 
 * Props:
 *    pmUser      PmUser object
 */
class PmUserCard extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render = () => {
    const pmUser = this.props.pmUser;
    const data = pmUser.data;

    return (
      <div className="pmuser-card">
        <div className="avatar"><PmUserIcon size="lg" border={false} pmUser={pmUser}/></div>
        <div className="name">{data.full_name}</div>
        <div className="email">{data.email}</div>
      </div>
    );
  }
}

export default PmUserCard;

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";


/**
 * Props:
 *  compact
 *  activity
 *  activityView
 */
export class ActivityDueDate extends React.Component {
  constructor(props) {
    super(props);
    this.dueDateModalRef = React.createRef();
  }

  handleClick = (event) => {
    if (this.dueDateModalRef) {
      const modal = this.dueDateModalRef.current;
      if (modal)
        modal.open(event);
    }
  }

  render = () => {
    const data = this.props.activity.data;

    return data.due_date && (
      <span className="duedate">
        <span
            title={data.due_date ? "Edit due date" : "Set due date"} 
            className={`duedatecal ${data.due_date_status}`} 
            onClick={this.handleClick}>
        {(data.due_date_status === "complete") && (
          <i className="bi bi-calendar-check-fill"></i>
        ) || (data.due_date_status === "cancelled") && (
          <i className="bi bi-calendar-x-fill"></i>
        ) || (data.due_date_status === "past") && (
          <i className="bi bi-calendar-fill"></i>
        ) || (data.due_date_status === "on") && (
          <i className="bi bi-calendar-fill"></i>
        ) || (
          <i className="bi bi-calendar"></i>
        )}
          &nbsp;<span className="ms-2">{data.due_date}</span>
          <ActivityDueDateModal 
            ref={this.dueDateModalRef}
            activity={this.props.activity} 
          />
        </span>
      </span>
    );
  }
}


/**
 * Props:
 *  activity
 *  activityView
 */
export class ActivityDueDateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { due_date: this.props.activity.data.due_date }, 
      modalOpened: false
    }
  }

  close = () => {
    this.setState({ modalOpened: false });
  };

  open = (event) => {
    event.stopPropagation();
    this.setState({ modalOpened: true });
  };

  handleChange = (event) => {
    let { name, value } = event.target;

    if (event.target.type === "checkbox") {
      value = event.target.checked;
    }

    const data = { ...this.state.data, [name]: value };
    this.setState({ data });
  };

  setActivityDueDate = async (due_date) => {
    const res = await this.props.activity.setDueDate(due_date);
  }

  setDueDate = (e) => {
    this.close();
    this.setActivityDueDate(this.state.data.due_date);
  }

  removeDueDate = (e) => {
    this.close();
    this.setActivityDueDate(null);
  }      

  render = () => {
    const data = this.props.activity.data;

    return (
      <>
        <Modal isOpen={this.state.modalOpened} toggle={this.close} backdrop="static" keyboard={false} size="sm">
          <ModalHeader toggle={this.close}>{ data.due_date ? "Edit" : "Set" } due date</ModalHeader>
          <ModalBody>
            <Form>

              <div className="row">
                <div className="col">
                  <FormGroup>
                    <Label for="due_date">Due date</Label>
                    <Input
                      type="date"
                      name="due_date"
                      id="due_date"
                      value={this.state.data.due_date ? this.state.data.due_date : ''}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </div>
              </div>

            </Form>
          </ModalBody>
          <ModalFooter>
            { data.due_date && (
            <Button
              color="danger"
              onClick={this.removeDueDate}
            >
              Remove
            </Button>
            )}
            <Button
                color="secondary"
                onClick={this.close}
              >
                Cancel
            </Button>
            <Button
                color="primary"
                onClick={this.setDueDate}
              >
              Apply
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
import React, { Component } from "react";
// import ActivityModal from "./ActivityModal";
// import ActivityDetails from "./ActivityDetails";
// import InlineEditor from "./InlineEditor";
import ActivityContextMenu from './ActivityContextMenu';
import Activity from '../backend/Activity';
import { ChildActivities } from '../backend/Activities';
import ActivityHead from './ActivityHead';
import ActivityLabelsView from './ActivityLabelView';
import ActivitiesView from './ActivitiesView';
import ActivityBreadcrumbs from './ActivityBreadcrumbs';
import ActivityDetails from "./ActivityDetails";
import { ActivityJournalEntries } from '../components/ActivityJournalEntries';
import { isElementInViewport } from './utils';
import { ActivityDueDate } from "./ActivityDueDate";
import { ActivityEstimatedDuration } from "./ActivityEstimatedDuration";
import ActivityMembers from "./ActivityMembers";
import { ActivityJournalIcon } from './ActivityJournal';
import ActivityIcon from "./ActivityIcon";
import ActivityOpener from "./ActivityOpener";
import ActivityMainPaneOpener from './ActivityMainPaneOpener';


/**
 *  View for an activity.
 * 
 *  Props:
 *    activity:           Activity object or |null
 *    isSortable
 *    dragging
 *    showPositionBadge   show a position badge on top right corner of activity
 *    showBreadcrumbs
 *    hidePrivate
 *    hideNonPrioritary
 *    hideCancelled
 *    hideCompleted
 *    showArchived
 *    list
 *    level
 *    hideSubactivities
 *    hideIcon
 *    detailsBelow        deprecated
 *    hideJournal
 * 
 *    onChildDeleted:     called when activity is deleted
 *    onChildCreated:     called when new child activity has been created
 *    onBreadcrumbClick:  
 *    onClick:            on whole activity (e.g. search results)
 * 
 */
class ActivityView extends Component {
  constructor(props) {
    super(props);
    if (!props.activity)
      console.log("Null activity in props");
    this.activity = this.props.activity;
    this.state = {
      editModalOpen: false,
      data: this.activity.data,
      modalData: null,
      draggable: false,         // true when activity can be dragged.
      dragging: false,          // true if dragging this element
      isOpen: this.activity.getIsOpen(),
      journalOpen: this.activity.getIsJournalOpen(),
      dragOver: false,          // false: not over; "head": drag over head; "left": drag over left
      count: 0,
      showTools: false,
    };

    // Counters needed to tackle with drag enter/leave on child elements
    // https://stackoverflow.com/a/21002544
    this.dragLeftCounter = 0;

    this.activityRef = React.createRef();
    this.activityHead = React.createRef();
    this.subActivitiesRef = React.createRef();
    this.contextMenuRef = React.createRef();

    this.journalEntriesRef = React.createRef();
  }

  // Called when component is created
  componentDidMount = () => {
    if (this.props.activity.isJustCreated()) {
      this.props.activity.clearJustCreated();
      this.scrollIntoView();
      // this.titleEditorRef.current.focus();
    }

    // Register this ActivityView for notifications by the BackendObject (Activity)
    this.props.activity.registerListener(this);
  }

  // Invoked immediately before this component is unmounted and destroyed
  componentWillUnmount = () => {
    // Unregister this ActivityView from notifications by the BackendObject (Activity)
    this.props.activity.unregisterListener(this);
  }

  // Open all subactivities
  openAll = () => {
    if (this.subActivitiesRef.current) 
      this.subActivitiesRef.current.openAll();  
  }

  // Close all subactivities
  closeAll = () => {
    if (this.subActivitiesRef.current) 
      this.subActivitiesRef.current.closeAll();  
  }

  // Action
  // Show the context menu
  handleShowContextMenu = (event) => {
    event.stopPropagation();
    this.contextMenuRef.current.show(event);
  }

  // Called when the related BackendObject (Activity) has been updated
  backendObjectUpdated = (activity) => {
    //console.log("ActivityView.backendObjectUpdated(): object was updated.");
    this.activity = activity;
    this.setState({ 
      data: this.activity.data,
      isOpen: this.activity.getIsOpen(),
      journalOpen: this.activity.getIsJournalOpen(),
    }); // rerender!
  }

  // Called when component updates
  componentDidUpdate = async (prevProps) => {
    if (this.props.activity !== prevProps.activity) {
      this.activity = this.props.activity;
      this.setState({
        editModalOpen: false,
        data: this.activity.data,
        modalData: null,
        draggable: false,         // true when activity can be dragged.
        dragging: false,          // true if dragging this element
        isOpen: this.activity.getIsOpen(),
        journalOpen: this.activity.getIsJournalOpen(),
      });
    }
  }

  // Brings the activity into view, if it isn't
  scrollIntoView = () => {
    const el = this.activityRef.current;
    if (el && !isElementInViewport(el))
      el.scrollIntoView({ behavior: "smooth" });
  }

  // Action
  // Toggle details open/close
  toggleDetails = (event) => {
    event.stopPropagation();
    if (this.state.isOpen)
      this.closeDetails();
    else
      this.openDetails();
  }

  openDetails = async (event) => {
    if (this.state.isOpen)
      return;
    this.activity.setIsOpen(true);
  }

  closeDetails = async (event) => {
    this.activity.setIsOpen(false);
  }

  // Action
  // Delete current activity (with confirmation)
  handleDelete = async () => {
    if (window.confirm("Do you really want to delete this activity? All sub-activities will be deleted as well. This operation cannot be reversed.")) {
      let uuid = this.activity.uuid;
      await this.activity.delete();
      if (this.props.onDeleted)
        this.props.onDeleted(uuid);
    }
  }

  // Action
  // Create a new child activity
  handleCreateNewChild = () => {
    console.log("ActivityView.handleCreateNewChild()");
    this.openDetails();
    let activityView = this;
    this.setState({ isOpen: true }, () => {
      activityView.subActivitiesRef.current.createNewAtEnd();
    });
  }

  // Prop callback
  // Called when a new child has been created
  handleChildCreated = (activity) => {
    console.log("ActivityView.handleChildCreated()");
    this.refresh();
  }

  // Prop callback
  // Called when a child activity has been deleted
  // uuid: ID of the deleted activity
  handleChildDeleted = () => {
    console.log("ActivityView.handleChildDeleted()")
//    alert('ActivityView.childDeleted() [child of: ' + this.activity.data.title + ']')
    this.refresh();
  }

  // Refresh this activity data
  // NOTE: Does NOT refresh children!
  refresh = async () => {
    console.log("ActivityView.refresh()");
    if (!this.activity)
      return;
    await this.activity.refresh();
  }

  setChildActivitiesType = async (type) => {
    if (!this.subActivitiesRef.current)
      return;
    await this.subActivitiesRef.current.setActivitiesType(type);
  }

  setActivityTitle = async (title) => {
    await this.activity.setTitle(title);
  }

  setPrivate = async (priv) => {
    const res = await this.activity.setPrivate(priv);
  }

  archive = async () => {
    const res = await this.activity.archive();
  }

  unarchive = async () => {
    const res = await this.activity.unarchive();
  }

  // Prop callback.
  // Create a new activity after the following with the given title.
  createNewAfterThis = async (title) => {
    // console.debug("AcivityView.handleEnterKeyPressedInTitle()");
    this.props.createNewAfter(this.activity, title);
  }

  // -----------------------------------------------------------------------------
  // Drag & drop
  // -----------------------------------------------------------------------------

  setDraggableOn = (event) => {
    event.stopPropagation();
    if (!this.props.isSortable)
      return;
    this.setState({draggable: true });
  }

  setDraggableOff = (event) => {
    event.stopPropagation();
    if (!this.props.isSortable)
      return;
    this.setState({ draggable: false });
  }

  handleDragStart = (event) => {
    event.stopPropagation();
    if (!this.props.isSortable)
      return;
    console.log("ActivityView.handleDragStart()");
    this.setState({ dragging: true });

    // // Ref: https://www.kryogenix.org/code/browser/custom-drag-image.html
    // let node = this.activityRef.current;
    // let dx = event.clientX - node.getBoundingClientRect().x;
    // let dy = event.clientY - node.getBoundingClientRect().y;
    // let crt = node.cloneNode(true);
    // crt.style.width = node.offsetWidth + 'px';
    // crt.style.backgroundColor = "red";
    // crt.style.position = "absolute";
    // crt.style.top = "0px";
    // crt.style.right = "0px";
    // crt.style.transform = "rotate(-2deg)";
    // crt.style.transformOrigin = "top left";
    // document.body.appendChild(crt);
    // event.dataTransfer.setDragImage(crt, dx, dy);
    // node.style.visibility = 'hidden';

    event.dataTransfer.dropEffect = "move";
    window.activityDragging = {
      activity: this.activity,
      originList: this.props.list,
      // node: node,
      // dx: dx,
      // dy: dy,
    }
  }

  handleDrag = (event) => {
    // window.activityDragging.node.style.left = event.clientX - window.activityDragging.dx;
    // window.activityDragging.node.style.top = event.clientX - window.activityDragging.dy;
  }

  handleDragEnd = (event) => {
    if (!this.props.isSortable)
      return;
    console.log("ActivityView.handleDragEnd()");
    this.setState({ dragging: false });
    // window.activityDragging.node.style.visibility = 'visible';
    // window.activityDragging.node.style.position = 'static';
  }

  handleDragEnterLeft = (event) => {
    if (!this.props.isSortable)
      return;
    if (this.props.dragging || this.state.dragging)
      return;
    event.preventDefault();
    event.stopPropagation();
    this.dragLeftCounter++;
    this.setState({ dragOver: "left" });
  }
  
  handleDragLeaveLeft = (event) => {
    if (!this.props.isSortable)
      return;
    if (this.props.dragging || this.state.dragging)
      return;
    event.preventDefault();
    event.stopPropagation();
    if (--this.dragLeftCounter === 0)
      this.setState({ dragOver: false });
  }
  
  handleDragOverLeft = (event) => {
    if (!this.props.isSortable)
      return;
    if (this.props.dragging || this.state.dragging)
      return;
    event.preventDefault();
  }

  handleDropLeft = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ dragOver: false });

    // console.log("ActivityView.handleDropLeft()");
    // return;

    if (!this.props.isSortable)
      return;
    if (this.props.dragging || this.state.dragging)
      return;

    // Move dragged activity as next sibling of this activity 
    const activity = window.activityDragging.activity;
    const parent = this.props.list.props.activities.parent;
    await activity.moveTo(parent, this.props.activity);
    window.activityDragging.originList.movedOut(activity);
    if (this.props.list)
      this.props.list.refresh();
  }

  showTools = () => {
    this.setState({ showTools: true });
  }

  hideTools = () => {
    this.setState({ showTools: false });
  }

  getActivities = () => {
    if (!this.activities)
      this.activities = new ChildActivities(this.activity);

    return this.activities;
  }

  render = () => {
    // console.debug("ActivityView.render()");
    const data = this.state.data;
    const level = this.props.level ? this.props.level : 1;

    if (data.is_archived && !this.props.showArchived)
      return null;

    const minimize = (this.props.hideNonPrioritary && data.priority_inner < Activity.PRIORITY_HIGH)
                     || (this.props.hidePrivate && data.is_private)
                     || (this.props.hideCancelled && data.state === Activity.STATE_CANCELLED)
                     || (this.props.hideCompleted && data.state === Activity.STATE_COMPLETED);

    let hasPriority = false;
    let priorityClass = "";
    if (this.props.activity.data.priority !== Activity.PRIORITY_NORMAL) {
      hasPriority = true;
      if (this.props.activity.data.priority === Activity.PRIORITY_HIGH)
        priorityClass = 'priority-high';
      if (this.props.activity.data.priority === Activity.PRIORITY_LOW)
        priorityClass = 'priority-low';
      if (this.props.activity.data.priority_local == Activity.PRIORITY_NORMAL)
        priorityClass = priorityClass + ' inherited';
    }

    if (minimize)
      return null;
//    return <div className="activity minimized"></div>

    // Background (image, color or gradient)
    let bgClass = "";
    let bgStyle = {};
    if (this.props.view === "grid") {
      bgClass = this.props.activity.getBgClass();
      bgStyle = this.props.activity.getBgStyle();
      bgStyle['backdropFilter'] = 'blur(20px)';
    }
    bgStyle['--level'] = level;

    return (
      <>
        <div
            className={`activity
                        ${bgClass}
                        level-${level}
                        ${data.is_private ? "private" : ""}
                        activity-type-${data.type}
                        activity-state-${data.state}
                        ${this.props.isSortable ? " sortable" : ""}
                        ${this.state.isOpen ? " open" : ""}
                        ${this.props.dragging || this.state.dragging ? " dragging" : ""}
                        ${data.is_archived ? " archived" : ""}`}
            draggable={this.state.draggable}
            //dragging={this.props.dragging || this.state.dragging}
            ref={this.activityRef}
            onClick={this.props.onClick}
            onContextMenu={this.handleShowContextMenu}

            onDragStart={this.handleDragStart}
            onDrag={this.handleDrag}
            onDragEnd={this.handleDragEnd}

            onDragEnter={this.handleDragEnterLeft}
            onDragLeave={this.handleDragLeaveLeft}
            onDragOver={this.handleDragOverLeft}
            onDrop={this.handleDropLeft}
            style={bgStyle}
        >
          <div className="state"></div>

          { this.props.showPositionBadge && (
          <div className="position">{data.position}</div>
          )}

          <ActivityContextMenu 
            ref={this.contextMenuRef}
            activity={this.props.activity}
            activityView={this}
          />

          <div 
            title={this.activity.data.uuid}
            className={`handle ${this.state.dragOver === "left" ? "drop" : ""}`} 
            onMouseOver={this.setDraggableOn}
            onMouseOut={this.setDraggableOff}
          >
          { !this.props.hideSubactivities && (
            <ActivityOpener
              isOpen={this.state.isOpen}
              has_subactivities={this.state.data.has_subactivities}
              activityView={this}
            />
          )}
          </div>

        {(this.props.view == 'list') && (
          <div 
            className="heading"
            onMouseOver={this.showTools}
            onMouseOut={this.hideTools}
          >
          {!this.props.hideIcon && (
            <ActivityIcon
              activity={this.props.activity}
              activityView={this}
              onContextMenu={this.handleShowContextMenu}
            />
          )}

            <ActivityHead
              ref={this.activityHead}
              activity={this.props.activity}
              activityView={this}
              enterSplitsActivity={true}
              dragging={this.props.dragging || this.state.dragging}
            />

            <div class="details">
              <ActivityMainPaneOpener 
                  activity={this.props.activity} 
                  show={this.state.showTools}
                />

              <ActivityJournalIcon
                show={this.state.showTools}
                activity={this.props.activity}
              />

              <ActivityDetails
                compact={false}
                activity={this.props.activity}
              />

              <ActivityDueDate
                compact={false} 
                activity={this.props.activity} 
                activityView={this} 
              />

              <ActivityEstimatedDuration
                compact={false} 
                activity={this.props.activity}  
                activityView={this} 
              />

              <ActivityMembers
                compact={false} 
                activity={this.props.activity} 
                activityView={this} 
              />
            </div>
          </div>
          )}

          <ActivityLabelsView
            compact={this.state.aboveCompact} 
            activity={this.props.activity} 
          />

          <div className="mid">
            {/* <div><small>{this.props.activity.uuid}</small></div> */}

          {this.props.showBreadcrumbs && (
            <ActivityBreadcrumbs 
              activity={this.activity}
              onClick={this.props.onBreadcrumbClick}
            />
          )}

          {this.state.journalOpen && !this.props.hideJournal && (
            <ActivityJournalEntries
              ref={this.journalEntriesRef}
              activity={this.activity}
            />
          )}
          </div>

        { !this.props.hideSubactivities && this.state.isOpen && (
          <ActivitiesView
            ref={this.subActivitiesRef}
            view={this.props.view}
            level={this.props.level ? this.props.level + 1 : 1}
            activities={this.getActivities()}
            dragging={this.props.dragging || this.state.dragging}
            showArchived={this.props.showArchived}
            hidePrivate={this.props.hidePrivate}
            hideNonPrioritary={this.props.hideNonPrioritary}
            hideCancelled={this.props.hideCancelled}
            hideCompleted={this.props.hideCompleted}
            onCreated={this.handleChildCreated}
            onDeleted={this.handleChildDeleted}
          />
          )}

          <div className={`priority ${priorityClass}`}></div>

      {(this.state.dragOver === "left") && (
          <div className="drop-line"></div>  
      )} 
        </div>
      </>
    )
  };
}

export default ActivityView;
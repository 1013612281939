import React from 'react';
import { Editor } from '@tinymce/tinymce-react';


/**
 * Props:
 *    placeholder     Placeholder text 
 *    startStatic     true to start the component in static mode and allow editing upon click.
 * 
 *    onChange
 */
class HtmlEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      text: this.props.value, 
      changed: false,
      isStatic: this.props.startStatic,
    };
    this.timeout = null;

    this.editorRef = React.createRef();
  }

  change = () => {
    if (this.state.changed && this.props.onChange) {
      this.props.onChange(this.state.text);
      this.setState({ changed: false });
    }
  }

  getContent = () => {
    if (this.editorRef.current)
      return this.editorRef.current.getContent();
    else
      return null;
  }

  scheduleChange = () => {
    let editor = this;
    if (this.timeout)
      return;

    this.timeout = setTimeout(() => { 
      editor.change();
      this.timeout = null;
    }, 2000);
  }

  handleEditorChange = (content, editor) => {
    this.setState({ text: content, changed: true });
    this.scheduleChange();
  }

  startEditing = (event) => {
    this.setState({ isStatic: false });
  }

  handleBlur = () => {
    this.change();
    this.setState({ 
      isStatic: true, 
    });
  }

  render() {

    let s = '';
    if (!this.state.isStatic) {
      s = this.state.changed ? "changed" : "uptodate";
    }

    return (
      <div 
        className={`htmleditor ${s}`} 
      >
        {(this.state.isStatic && (
        <div 
          className="htmleditor-static" 
          dangerouslySetInnerHTML={{__html: this.state.text}}
          onClick={this.startEditing}
        ></div>
        )) || (
          <Editor
            onInit={(evt, editor) => this.editorRef.current = editor}
            onBlur={this.handleBlur}
            init={{
              // Ref: https://www.tiny.cloud/docs/general-configuration-guide/use-tinymce-distraction-free/
              menubar: false, 
              placeholder: this.props.placeholder,
              body_class: 'my_class',
              plugins: [ 'lists', 'link', 'quickbars' ],          
              // toolbar:  [
              //   "styles | bold italic underline strikethrough | backcolor forecolor", 
              //   "link unlink | alignleft aligncenter alignright | bullist numlist | outdent indent"
              // ],
              inline: true,
              toolbar: false,
              // plugins: [ 'quickbars' ],
              // quickbars_insert_toolbar: 'undo redo',
              quickbars_insert_toolbar: false,
              // quickbars_selection_toolbar: 'styles | bold italic underline strikethrough | hilitecolor forecolor | link | bullist numlist | outdent indent',
              quickbars_selection_toolbar:
                "styles | bold italic underline strikethrough | backcolor forecolor | " +
                "link unlink | bullist numlist | outdent indent"
              ,
            }}      
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            value={this.state.text}
            onEditorChange={this.handleEditorChange}
          />
        )}
      </div>
    );
  }


}

export default HtmlEditor;

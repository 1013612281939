import PmUser from '../backend/PmUser';
import PmUserIcon from './PmUserIcon';
import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form
} from "reactstrap";


/**
 * Props:
 *  compact
 *  activity
 *  activityView
 */
export default class ActivityMembers extends React.Component {
  constructor(props) {
    super(props);
    this.membersModalRef = React.createRef();
    this.activity = this.props.activity;
    this.state = {
      editModalOpen: false
    }
  }

  // Edit members
  handleMemberClick = (event) => {
    if (this.membersModalRef) {
      const modal = this.membersModalRef.current;
      if (modal)
        modal.open(event);
    }
  }

  render = () => {
    let pmUsers = null;
    const pmLocalUsers = this.props.activity.getMembers();
    const isInherited = pmLocalUsers.length == 0;
    if (isInherited)
      pmUsers = this.props.activity.getAllMembers();
    else
      pmUsers = pmLocalUsers;

    return (
      <>
        <div className={`members ${isInherited ? "inherited" : ""} overlap`}>
          {pmUsers.map(pmUser =>
            <PmUserIcon key={pmUser.uuid} size="sm" pmUser={pmUser} onClick={this.handleMemberClick} />
          )}
        </div>
        <ActivityMembersModal 
          ref={this.membersModalRef}
          activity={this.props.activity} 
        />
      </>
    );
  }
}


/**
 * Props:
 *  activity
 *  activityView
 */
export class ActivityMembersModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uuids_of_members: [], 
      modalOpened: false
    }

    this.members = null;
  }

  componentDidMount = async () => {
    this.members = PmUser.getAll();
    this.setState({
      uuids_of_members: this.props.activity.data.uuids_of_members || [], 
    });
  }

  close = () => {
    this.setState({ modalOpened: false });
  };

  open = (event) => {
    event.stopPropagation();
    this.setState({ modalOpened: true });
  };

  setMember = (uuid, present) => {
    let uuids_of_members = [...this.state.uuids_of_members];
    if (present) {
      // Add to array
      uuids_of_members.push(uuid);
    }
    else {
      // Remove from array
      var index = uuids_of_members.indexOf(uuid);
      if (index !== -1) {
        uuids_of_members.splice(index, 1);
      }      
    }

    this.setState({ uuids_of_members: uuids_of_members });
  };

  setActivityMembers = async (uuids_of_members) => {
    await this.props.activity.setMembers(uuids_of_members);
  }

  setMembers = (e) => {
    this.close();
    this.setActivityMembers(this.state.uuids_of_members);
  }

  removeMembers = (e) => {
    this.close();
    this.setActivityMembers([]);
  }      

  render = () => {
    return (
      <>
        {this.state.modalOpened && (
        <Modal isOpen={true} toggle={this.close} backdrop="static" keyboard={false} size="sm">
          <ModalHeader toggle={this.close}>
            Members
          </ModalHeader>
          <ModalBody>
          {this.members && (
            <Form>
              { [...this.members].map(([uuid, pmUser]) => { 
                const isMember = this.state.uuids_of_members.includes(uuid); 
                return (
                <div 
                  key={uuid} 
                  className={`member-selection ${isMember ? 'selected' : ''}`}
                  onClick={() => this.setMember(uuid, !isMember)}
                >
                  <span className="me-2"><PmUserIcon pmUser={pmUser} /></span> 
                  {pmUser.data.full_name}
                  { isMember && (<i className="bi bi-check-lg ms-2"></i>)}
                </div>
                )}
              )}
            </Form>
          )}
          </ModalBody>
          <ModalFooter>
            { this.props.activity.data.uuids_of_members.size && (
            <Button
              color="danger"
              onClick={this.removeMembers}
            >
              Remove all
            </Button>
            )}
            <Button
                color="secondary"
                onClick={this.close}
              >
                Cancel
            </Button>
            <Button
                color="primary"
                onClick={this.setMembers}
              >
              Apply
            </Button>
          </ModalFooter>
        </Modal>
        )}
      </>
    );
  }
}